@charset "utf-8";

/**
 * 팝업
 */

#wrap .popup-btn {
  position: sticky;
  bottom: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}

#wrap .popup-btn button {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px 10px 0 10px;
  color: #ffffff;
  background: var(--theme-blue-primary);
  border-color: var(--theme-blue-primary);
}

/**/
#wrap .popup-standard {
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 101;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
}
#wrap .popup-standard.active {
  opacity: 1;
  visibility: visible;
}
#wrap .popup-standard .popup-box {
  width: 80vw;
  max-width: 270px;
  border-radius: 20px 20px 0 20px;
  background: #ffffff;
  text-align: center;
}
#wrap .popup-standard .popup-cont {
  position: relative;
}
#wrap .popup-standard .popup-cont .title {
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #171717;
  margin: 26px 0 20px 0;
}
#wrap .popup-standard .popup-cont .text {
  margin: 20px 0 0 0;
  line-height: 20px;
  font-size: 13px;
  color: #171717;
}
#wrap .popup-standard .popup-cont .button {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  border-top: 1px solid #f1f1f1;
}
#wrap .popup-standard .popup-cont .button .btn {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
#wrap .popup-standard .popup-cont .button .btn + .btn {
  border-left: 1px solid #f1f1f1;
  margin: 0;
}
#wrap .popup-standard .popup-cont .button .btn_01 {
  color: var(--theme-text-secondary);
  background: transparent;
}
#wrap .popup-standard .popup-cont .button .btn_02 {
  color: var(--theme-blue-primary);
  background: transparent;
}
#wrap .popup-standard .popup-cont .button .btn_03 {
  color: var(--theme-text-secondary);
  background: transparent;
}

#wrap .popup-layer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  transform: translateX(-50%);
}
#wrap .popup-layer.active {
  opacity: 1;
  visibility: visible;
}
#wrap .popup-layer .popup-box {
  padding: 0 20px;
  border-radius: 5px 5px 0 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: calc(var(--vh, 1vh) * 100 - 51px);
  width: 100%;
}
#wrap .popup-layer .popup-bars {
  display: flex;
  margin: 0 0 30px 0;
  height: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#wrap .popup-layer .popup-bars i {
  display: block;
  width: 50px;
  height: 5px;
  background: var(--theme-text-teritary);
  border-radius: 5px;
}
#wrap .popup-layer .popup-cont {
  text-align: center;
  flex: 1;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#wrap .popup-layer .popup-cont::-webkit-scrollbar {
  display: none;
}

#wrap .popup-layer .popup-cont .title {
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #171717;
}
#wrap .popup-layer .popup-cont .text {
  margin: 10px 0 0 0;
  line-height: 25px;
  font-size: 14px;
  color: #171717;
}
#wrap .popup-layer .popup-cont .button {
  margin: 25px 0 0 0;
  display: flex;
  flex-direction: column;
}
#wrap .popup-layer .popup-cont .button .btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #303030;
  border-radius: 10px 10px 0 10px;
}
#wrap .popup-layer .popup-cont .button .btn + .btn {
  margin: 0;
}
#wrap .popup-layer .popup-cont .button .btn_01 {
  color: #ffffff;
  background: var(--theme-blue-primary);
  border-color: var(--theme-blue-primary);
}
#wrap .popup-layer .popup-cont .button .btn_02 {
  color: var(--theme-blue-primary);
  background: #ffffff;
  border-color: #ffffff;
}

#wrap .popup-bottom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 101;
  opacity: 0;
  visibility: hidden;
}
#wrap .popup-bottom.active {
  opacity: 1;
  visibility: visible;
}
#wrap .popup-bottom .popup-box {
  width: 500px;
  max-width: 100%;
  text-align: center;
  padding: 0 20px 35px;
}
#wrap .popup-bottom .popup-box .popup-cont {
  padding: 15px;
  border-radius: 5px;
  background: #fff;
}
#wrap .popup-bottom .popup-box .popup-button {
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}
#wrap .popup-bottom .popup-box .popup-button .btn {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid transparent;
}
#wrap .popup-bottom .popup-box .popup-button .btn_01 {
  color: #171717;
  background: #fff;
  border-color: transparent;
}
#wrap .popup-bottom .popup-cont .button {
  display: flex;
  align-items: center;
}
#wrap .popup-bottom .popup-cont .button .btn {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wrap .popup-bottom .popup-cont .button .btn + .btn {
  margin: 0;
}
#wrap .popup-bottom .popup-cont .button .btn_01 {
  color: #171717;
}
#wrap .popup-bottom .popup-cont .button .btn_02 {
  color: var(--theme-blue-primary);
}
#wrap .popup-bottom .popup-cont .button .btn_03 {
  color: var(--theme-red-primary);
}

#wrap .popup-moment {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 88px 0;
  background: #191919;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  pointer-events: none;
}
#wrap .popup-moment:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background: #191919;
  z-index: 5;
}
#wrap .popup-moment:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background: #191919;
  z-index: 5;
}
#wrap .popup-moment.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/**/
#wrap #common-popup-moment {
}
#wrap #common-popup-moment .popup-box {
  position: relative;
  width: 465px;
  max-width: 100%;
  height: 100%;
  background: transparent;
  flex: 1;
}
#wrap #common-popup-moment .moment-container {
  height: 100%;
}
#wrap #common-popup-moment .moment-container .moment-inner {
  height: 100%;
}
#wrap #common-popup-moment .moment-container .moment-inner .vod {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  overflow: hidden;
}
#wrap #common-popup-moment .moment-container .moment-inner .vod img,
#wrap #common-popup-moment .moment-container .moment-inner .vod video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-moment .moment-container .moment-inner .lodging {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 30px;
  right: 30px;
  display: flex;
  align-items: center;
}
#wrap #common-popup-moment .moment-container .moment-inner .lodging .title {
  display: flex;
  align-items: center;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .ico {
  border-radius: 5px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 18px 0 0;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .ico
  img {
  display: block;
  width: 17px;
  height: auto;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .con {
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .con
  .sub {
  display: flex;
  align-items: center;
  margin: 0 0 4px 0;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .con
  .sub
  i {
  width: 11px;
  height: 15px;
  background: url('../img/icon-checkin-cert.png') no-repeat 50% 50% / cover;
  margin: 0 4px 0 0;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .con
  .sub
  p {
  line-height: 13px;
  font-size: 11px;
  font-weight: bold;
  color: var(--theme-blue-secondary);
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .title
  .con
  .tit {
  line-height: 16px;
  font-size: 14px;
  color: #fff;
}
#wrap #common-popup-moment .moment-container .moment-inner .lodging .action {
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .action
  a.more {
  display: block;
  border-radius: 5px;
  display: flex;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 0 12px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  align-items: center;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .action
  a.popup-close {
  margin-left: 15px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .lodging
  .action
  a.popup-close
  img {
  width: 18px;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  padding: 0 20px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text {
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text .txt {
  line-height: 19px;
  font-sdize: 16px;
  color: #ffffff;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text .tag {
  margin: 3px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 16px;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text .tag p {
  display: inline-flex;
  line-height: 16px;
  font-size: 13px;
  color: rgb(255 255 255 / 0.85);
  align-items: center;
  vertical-align: top;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .text
  .tag
  p
  + p {
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .text
  .tag
  p
  + p:before {
  content: '';
  margin: 0 3px;
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
  display: flex;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text .hash {
  margin: 13px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 14px;
  color: #fff;
  max-height: 28px;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .text .hash p {
  display: inline-flex;
  font-size: 12px;
  color: #fff;
  align-items: center;
  vertical-align: top;
  margin: 0 8px 1px 0;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .user {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .user .img {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  overflow: hidden;
  margin: 0 5px 0 0;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .img
  img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .user .con {
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 35px;
  overflow: hidden;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  div {
  position: relative;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  div
  + div {
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  div
  + div:before {
  content: '';
  margin: 15px 5px 17px;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #fff;
  display: inline-block;
  vertical-align: top;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  .name {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  vertical-align: top;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  .button {
  display: inline-block;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  .button
  a.btn {
  display: inline-flex;
  height: auto;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  vertical-align: top;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  .button
  a.btn-follow {
  color: var(--theme-blue-secondary);
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .user
  .con
  .button
  a.btn-following {
  color: var(--theme-text-teritary);
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .progress {
  margin-top: 15px;
}
#wrap #common-popup-moment .moment-container .moment-inner .cont .progress i {
  display: block;
  width: 100%;
  height: 2px;
  background: var(--theme-text-secondary);
  position: relative;
  overflow: hidden;
  border-radius: 1px;
}
#wrap
  #common-popup-moment
  .moment-container
  .moment-inner
  .cont
  .progress
  i
  .percent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: 100%;
  background: #ffffff;
  border-radius: 1px;
}
#wrap #common-popup-moment .moment-container .moment-inner .option {
  position: absolute;
  bottom: 52px;
  left: 100%;
  margin-left: 30px;
  padding: 0 20px;
}
#wrap #common-popup-moment .moment-container .moment-inner .option ul {
  display: flex;
  flex-direction: column;
}
#wrap #common-popup-moment .moment-container .moment-inner .option li {
}
#wrap #common-popup-moment .moment-container .moment-inner .option li + li {
  margin-top: 20px;
}
#wrap #common-popup-moment .moment-container .moment-inner .option li a {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#wrap #common-popup-moment .moment-container .moment-inner .option li i {
  width: 25px;
  height: 25px;
}
#wrap #common-popup-moment .moment-container .moment-inner .option li p {
  line-height: 16px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
}
#wrap #common-popup-moment .moment-container .moment-inner .option li i + p {
  margin-top: 3px;
}
#wrap #common-popup-moment .moment-button-prev {
  position: fixed;
  top: 50px;
  right: 100px;
  width: 70px;
  height: 70px;
  background: url('../img/icon-moment-prev.png') no-repeat 50% 50% / cover;
  cursor: pointer;
  z-index: 6;
}
#wrap #common-popup-moment .moment-button-next {
  position: fixed;
  bottom: 50px;
  right: 100px;
  width: 70px;
  height: 70px;
  background: url('../img/icon-moment-next.png') no-repeat 50% 50% / cover;
  cursor: pointer;
  z-index: 6;
}
#wrap #common-popup-moment .moment-alert {
  position: fixed;
  bottom: 107px;
  left: 0;
  z-index: 108;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  padding: 0 20px;
}
#wrap #common-popup-moment .moment-alert.animate {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
#wrap #common-popup-moment .moment-alert p {
  padding: 0 10px;
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 141 / 90%);
  border-radius: 5px 5px 0 5px;
  line-height: 17px;
  font-size: 14px;
  color: var(--theme-text-primary);
}
#wrap #common-popup-moment .view-option-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  z-index: 105;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}
#wrap #common-popup-moment .view-option-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-option-layer-box {
  padding: 0 20px 35px;
  width: 500px;
  max-width: 100%;
}
#wrap #common-popup-moment .view-option-layer-box ul {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
}
#wrap #common-popup-moment .view-option-layer-box ul + ul {
  margin-top: 10px;
}
#wrap #common-popup-moment .view-option-layer-box li {
}
#wrap #common-popup-moment .view-option-layer-box li + li {
  border-top: 1px solid #efefef;
}
#wrap #common-popup-moment .view-option-layer-box li a {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50px;
}
#wrap #common-popup-moment .view-report-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  z-index: 105;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}
#wrap #common-popup-moment .view-report-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-report-layer-box {
  padding: 0 20px 35px;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  z-index: 1;
  position: relative;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}
#wrap #common-popup-moment .view-report-layer-box .bars {
  display: flex;
  margin: 0 0 30px 0;
  height: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#wrap #common-popup-moment .view-report-layer-box .bars i {
  display: block;
  width: 50px;
  height: 5px;
  background: var(--theme-text-teritary);
  border-radius: 5px;
}
#wrap #common-popup-moment .view-report-layer-box .cons {
  text-align: center;
  flex: 1;
  border-radius: 0;
  background: transparent;
}
#wrap #common-popup-moment .view-report-layer-box .cons .title {
  margin: 0 0 20px;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-text-primary);
}
#wrap #common-popup-moment .view-report-layer-box .cons .list {
}
#wrap #common-popup-moment .view-report-layer-box .cons .list ul {
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li {
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li + li {
  border-top: 1px solid #f1f1f1;
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li .report {
  display: flex;
  align-items: center;
  height: 50px;
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li .report input {
  display: none;
}
#wrap
  #common-popup-moment
  .view-report-layer-box
  .cons
  .list
  li
  .report
  input
  ~ p {
  line-height: 1;
  font-size: 14px;
  color: var(--theme-text-primary);
}
#wrap
  #common-popup-moment
  .view-report-layer-box
  .cons
  .list
  li
  .report
  input
  ~ i {
  margin: 0 0 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: url('../img/icon-radio-checked.png') no-repeat 50% 50% / cover;
}
#wrap
  #common-popup-moment
  .view-report-layer-box
  .cons
  .list
  li
  .report
  input:checked
  ~ i {
  background: url('../img/icon-radio-checked-on.png') no-repeat 50% 50% / cover;
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li .text {
  display: none;
}
#wrap
  #common-popup-moment
  .view-report-layer-box
  .cons
  .list
  li
  .text
  textarea {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background: #f8f8f8;
  height: 125px;
  width: 100%;
  display: block;
}
#wrap #common-popup-moment .view-report-layer-box .cons .list li.on .text {
  display: block;
}
#wrap #common-popup-moment .view-report-layer-box .cons .action {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  background: #fff;
}
#wrap #common-popup-moment .view-report-layer-box .cons .action a.btn {
  display: flex;
  height: 44px;
  border-radius: 10px 10px 0 10px;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
#wrap #common-popup-moment .view-report-layer-box .cons .action a.btn + a.btn {
  margin-top: 6px;
}
#wrap #common-popup-moment .view-report-layer-box .cons .action a.btn_01 {
  background: transparent;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-moment .view-report-layer-box .cons .action a.btn_02 {
  background: var(--theme-blue-primary);
  color: #fff;
}
#wrap #common-popup-moment .view-report-layer-box .cons .action a.btn_disabled {
  pointer-events: none;
  background: #d2d2d2;
  color: #fff;
}
#wrap #common-popup-moment .view-block-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 105;
  opacity: 0;
  visibility: hidden;
}
#wrap #common-popup-moment .view-block-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-block-layer-box {
  width: 500px;
  max-width: 100%;
  text-align: center;
  padding: 0 20px 35px;
}
#wrap #common-popup-moment .view-block-layer-box .cons {
  padding: 15px;
  border-radius: 5px;
  background: #fff;
}
#wrap #common-popup-moment .view-block-layer-box .cons .image {
  width: 50px;
  margin: 0 auto 13px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
}
#wrap #common-popup-moment .view-block-layer-box .cons .image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-moment .view-block-layer-box .cons .title {
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  margin: 0 0 7px 0;
}
#wrap #common-popup-moment .view-block-layer-box .cons .title strong {
  font-weight: 700;
}
#wrap #common-popup-moment .view-block-layer-box .cons .text {
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-moment .view-block-layer-box .cons .button {
  margin: 22px 0 0 0;
}
#wrap #common-popup-moment .view-block-layer-box .cons .button {
  display: flex;
  align-items: center;
}
#wrap #common-popup-moment .view-block-layer-box .cons .button .btn {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wrap #common-popup-moment .view-block-layer-box .cons .button .btn_01 {
  color: #171717;
}
#wrap #common-popup-moment .view-block-layer-box .cons .button .btn_02 {
  color: var(--theme-blue-primary);
}
#wrap #common-popup-moment .view-block-layer-box .cons .button .btn_03 {
  color: var(--theme-red-primary);
}
#wrap #common-popup-moment .view-block-layer-box .action {
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}
#wrap #common-popup-moment .view-block-layer-box .action .btn {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid transparent;
}
#wrap #common-popup-moment .view-block-layer-box .action .btn_01 {
  color: #171717;
  background: #fff;
  border-color: transparent;
}
#wrap #common-popup-moment .view-login-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  z-index: 105;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}
#wrap #common-popup-moment .view-login-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-login-layer-box {
  padding: 0 20px 35px;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  z-index: 1;
  position: relative;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}
#wrap #common-popup-moment .view-login-layer-box .bars {
  display: flex;
  margin: 0 0 30px 0;
  height: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#wrap #common-popup-moment .view-login-layer-box .bars i {
  display: block;
  width: 50px;
  height: 5px;
  background: var(--theme-text-teritary);
  border-radius: 5px;
}
#wrap #common-popup-moment .view-login-layer-box .cons {
  text-align: center;
  flex: 1;
  border-radius: 0;
  background: transparent;
}
#wrap #common-popup-moment .view-login-layer-box .cons .title {
  margin: 0 0 20px;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-text-primary);
}
#wrap #common-popup-moment .view-login-layer-box .cons .text {
}
#wrap #common-popup-moment .view-login-layer-box .cons .action {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;
  background: #fff;
}
#wrap #common-popup-moment .view-login-layer-box .cons .action a.btn {
  display: flex;
  height: 44px;
  border-radius: 10px 10px 0 10px;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
#wrap #common-popup-moment .view-login-layer-box .cons .action a.btn + a.btn {
  margin-top: 6px;
}
#wrap #common-popup-moment .view-login-layer-box .cons .action a.btn_01 {
  background: transparent;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-moment .view-login-layer-box .cons .action a.btn_02 {
  background: var(--theme-blue-primary);
  color: #fff;
}
#wrap #common-popup-moment .view-login-layer-box .cons .action a.btn_disabled {
  pointer-events: none;
  background: #d2d2d2;
  color: #fff;
}
#wrap #common-popup-moment .view-share-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  z-index: 105;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}
#wrap #common-popup-moment .view-share-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-share-layer-box {
  padding: 0 20px 35px;
  width: 500px;
  max-width: 100%;
}
#wrap #common-popup-moment .view-share-layer-box ul {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
}
#wrap #common-popup-moment .view-share-layer-box ul + ul {
  margin-top: 10px;
}
#wrap #common-popup-moment .view-share-layer-box li {
}
#wrap #common-popup-moment .view-share-layer-box li + li {
  border-top: 1px solid #efefef;
}
#wrap #common-popup-moment .view-share-layer-box li a {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50px;
}
#wrap #common-popup-moment .view-delete-layer {
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 101;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
}
#wrap #common-popup-moment .view-delete-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-delete-layer-box {
  width: 80vw;
  max-width: 270px;
  border-radius: 10px 10px 0 10px;
  background: #ffffff;
  text-align: center;
}
#wrap #common-popup-moment .view-delete-layer-box .title {
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #171717;
  margin: 20px 0 16px 0;
}
#wrap #common-popup-moment .view-delete-layer-box .text {
  margin: 16px 0 0 0;
  line-height: 20px;
  font-size: 13px;
  color: #171717;
}
#wrap #common-popup-moment .view-delete-layer-box .button {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  border-top: 1px solid #f1f1f1;
}
#wrap #common-popup-moment .view-delete-layer-box .button .btn {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
#wrap #common-popup-moment .view-delete-layer-box .button .btn + .btn {
  border-left: 1px solid #f1f1f1;
}
#wrap #common-popup-moment .view-delete-layer-box .button .btn_01 {
  color: #171717;
  background: transparent;
}
#wrap #common-popup-moment .view-delete-layer-box .button .btn_02 {
  color: var(--theme-blue-primary);
  background: transparent;
}
#wrap #common-popup-moment .view-delete-layer-box .button .btn_03 {
  color: var(--theme-text-secondary);
  background: transparent;
}

#wrap #common-popup-moment .view-comment-layer {
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  height: 100%;
  z-index: 105;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
}
#wrap #common-popup-moment .view-comment-layer.on {
  opacity: 1;
  visibility: visible;
}
#wrap #common-popup-moment .view-comment-layer-box {
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  z-index: 1;
  position: relative;
  max-height: calc(100vh - 50px);
}
#wrap #common-popup-moment .view-comment-layer-box .bars {
  display: flex;
  margin: 0 0 30px 0;
  height: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#wrap #common-popup-moment .view-comment-layer-box .bars i {
  display: block;
  width: 50px;
  height: 5px;
  background: var(--theme-text-teritary);
  border-radius: 5px;
}
#wrap #common-popup-moment .view-comment-layer-box .cons {
  text-align: center;
  flex: 1;
  border-radius: 0;
  background: transparent;
  position: relative;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .title {
  margin: 0 0 20px;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-text-primary);
}
#wrap #common-popup-moment .view-comment-layer-box .cons .close {
  position: absolute;
  top: 0;
  right: 23px;
  cursor: pointer;
  display: none;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .close.active {
  display: block;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .close i {
  display: block;
  width: 19px;
  height: 19px;
  background: url('../img/icon-popup-close.png') no-repeat 50% 50% / 100% auto;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment {
  border-top: 0.5px solid #f1f1f1;
  height: 400px;
  max-height: 50vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment::-webkit-scrollbar {
  display: none;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .empty {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--theme-text-teritary);
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp {
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da {
  display: flex;
  align-items: flex-start;
  padding: 15px 20px;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da .img {
  margin: 0 10px 0 0;
  width: 39px;
  height: 39px;
  border-radius: 39px;
  overflow: hidden;
  flex-shrink: 0;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da .con {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .top {
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .top
  .name {
  line-height: 14px;
  font-size: 12px;
  color: #171717;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .top
  .time {
  margin-left: 10px;
  font-size: 10px;
  color: var(--theme-text-secondary);
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  max-height: 51px;
  text-align: left;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .txt
  .txt-more {
  float: left;
  color: var(--theme-text-secondary);
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .txt
  .txt-mension {
  float: left;
  color: var(--theme-blue-primary);
  margin: 0 5px 0 0;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt {
  margin: 17px 0 0 0;
  width: 100%;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  ul {
  display: flex;
  align-items: center;
  flex: 1;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li {
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li
  + li {
  margin-left: 20px;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li
  a {
  display: flex;
  align-items: center;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li
  i {
  width: 12px;
  height: 12px;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li
  p {
  margin-left: 6px;
  line-height: 12px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-like {
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-like
  i {
  background: url('../img/icon-comment-like.png') no-repeat 50% 50% / 100% auto;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-like.on
  i {
  background: url('../img/icon-comment-like-on.png') no-repeat 50% 50% / 100%
    auto;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-comment {
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-comment
  i {
  background: url('../img/icon-comment-comment.png') no-repeat 50% 50% / 100%
    auto;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-more {
  margin-left: auto;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .opt
  li.opt-more
  i {
  background: url('../img/icon-comment-more.png') no-repeat 50% 50% / 100% auto;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .more {
  margin: 22px 0 0 0;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .more.active {
  display: none;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .da
  .con
  .more
  a {
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp1 {
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .dp1.disabled {
  display: none;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp1.active {
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .dp1.active
  .da1 {
  background: #f8f8f8;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .dp1.active
  .more {
  display: none;
}
#wrap
  #common-popup-moment
  .view-comment-layer-box
  .cons
  .comment
  .dp1.active
  .dp2 {
  display: block;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp1 + .dp1 {
  border-top: 0.5px solid #f1f1f1;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp2 {
  display: none;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .dp2.active {
  display: block;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da2 {
}
#wrap #common-popup-moment .view-comment-layer-box .cons .comment .da2 .img {
  margin: 0 15px 0 11px;
  width: 23px;
  height: 23px;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .input {
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .input .inner {
  position: relative;
  border-radius: 5px 5px 0 5px;
  border: 1px solid #919191;
  flex: 1;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .input input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: transparent;
}
#wrap #common-popup-moment .view-comment-layer-box .cons .input button {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 10px;
  transform: translateY(-50%);
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-blue-primary);
}

/* moment mobile version */
@media (max-width: 1024px) {
  #wrap #common-popup-moment {
    padding: 0;
  }
  #wrap #common-popup-moment:before,
  #wrap #common-popup-moment:after {
    display: none;
  }
  #wrap #common-popup-moment .moment-container {
    border-radius: 0;
  }
  #wrap #common-popup-moment .moment-container .moment-inner .vod {
    border-radius: 0;
  }
  #wrap #common-popup-moment .moment-container .moment-inner .cont {
    background: linear-gradient(
      180deg,
      rgba(23, 23, 23, 0) 0%,
      rgba(23, 23, 23, 0.75) 100%
    );
    padding: 0 20px 30px;
  }
  #wrap #common-popup-moment .moment-container .moment-inner .cont .text {
    padding-right: 50px;
  }
  #wrap #common-popup-moment .moment-container .moment-inner .option {
    position: absolute;
    left: unset;
    right: 0;
    margin: 0;
    z-index: 5;
  }
  #wrap #common-popup-moment .moment-button-prev,
  #wrap #common-popup-moment .moment-button-next {
    display: none;
  }
}
/**/
#wrap #common-popup-share .share {
  margin: 14px 0 0 0;
  padding: 0 30px;
}
#wrap #common-popup-share .share a {
  position: relative;
  display: flex;
  height: 40px;
  border-radius: 10px 10px 0 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
#wrap #common-popup-share .share a + a {
  margin: 10px 0 0 0;
}
#wrap #common-popup-share .share a i {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
}
#wrap #common-popup-share .share a.kakao {
  color: #000000;
  background: #ffdf00;
}
#wrap #common-popup-share .share a.kakao .icon-share-kakao {
  background: url('../img/icon-share-kakao.png') no-repeat 50% 50% / 18px auto;
}

/**/
#wrap #common-popup-people .person {
  margin: 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wrap #common-popup-people .person button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 10px;
}
#wrap #common-popup-people .person .person-minus {
  background: #0080ff url('../img/icon-number-minus.png') no-repeat 50% 50% /
    100% auto;
}
#wrap #common-popup-people .person .person-minus.disabled {
  background: #f1f1f1 url('../img/icon-number-minus-disabled.png') no-repeat 50%
    50% / 100% auto;
}
#wrap #common-popup-people .person .person-plus {
  background: #0080ff url('../img/icon-number-plus.png') no-repeat 50% 50% /
    100% auto;
}
#wrap #common-popup-people .person .person-plus.disabled {
  background: #f1f1f1 url('../img/icon-number-plus.png') no-repeat 50% 50% /
    100% auto;
}
#wrap #common-popup-people .person .person-number {
  width: 125px;
  max-width: 100%;
  height: 30px;
  background: transparent;
  text-align: center;
  line-height: 1;
  font-size: 28px;
  font-weight: bold;
  color: var(--theme-blue-primary);
}
#wrap #common-popup-people .button {
  position: sticky;
  bottom: 10px;
}

/**/
#wrap #common-popup-calendar .popup-box {
}
#wrap #common-popup-calendar .calendar {
  margin: 10px -20px;
  max-height: 380px;
  overflow-y: auto;
}
#wrap #common-popup-calendar .calendar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#wrap #common-popup-calendar .calendar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
#wrap #common-popup-calendar .calendar ul {
}
#wrap #common-popup-calendar .calendar li {
  overflow: hidden;
}
#wrap #common-popup-calendar .calendar li .tit {
  margin: 26px 0 11px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
#wrap #common-popup-calendar .calendar li .tit strong {
  line-height: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #171717;
}
#wrap #common-popup-calendar .calendar li .tit span {
  margin-left: 10px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 500;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-calendar .calendar li .week {
  border-top: 0.5px solid var(--theme-text-teritary);
  border-bottom: 0.5px solid var(--theme-text-teritary);
  padding: 0 20px;
}
#wrap #common-popup-calendar .calendar li .week td {
  color: var(--theme-text-secondary);
}
#wrap #common-popup-calendar .calendar li .sel {
  padding: 0 20px 20px;
}
#wrap #common-popup-calendar .calendar li table {
  width: 100%;
  border-collapse: collapse;
}
#wrap #common-popup-calendar .calendar li table tr {
}
#wrap #common-popup-calendar .calendar li table td {
  position: relative;
  height: 40px;
  font-size: 14px;
}
#wrap #common-popup-calendar .calendar li table td button {
  position: relative;
  z-index: 2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #171717;
  background: transparent;
}
#wrap #common-popup-calendar .calendar li table td.disabled button {
  color: var(--theme-text-teritary);
}
#wrap #common-popup-calendar .calendar li table td.standard button {
  color: var(--theme-blue-primary);
}
#wrap #common-popup-calendar .calendar li table td.standard button:after {
  content: '';
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: var(--theme-blue-primary);
}
#wrap #common-popup-calendar .calendar li table td.selected button {
  background: var(--theme-blue-primary);
  color: #ffffff;
}
#wrap #common-popup-calendar .calendar li table td.selected-gap {
  background: rgb(0 128 255 / 5%);
}
#wrap #common-popup-calendar .calendar li table td.selected-start:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 50%;
  background: rgb(0 128 255 / 5%);
}
#wrap #common-popup-calendar .calendar li table td.selected-end:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 50%;
  bottom: 0;
  width: 50%;
  background: rgb(0 128 255 / 5%);
}
#wrap #common-popup-calendar .button {
  position: sticky;
  bottom: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}

/**/
#wrap #common-popup-inquiry .popup-box {
  height: calc(100vh - 51px);
}
#wrap #common-popup-inquiry .cont {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 45px - 61px - 55px - 30px - 81px);
  overflow-y: auto;
}
#wrap #common-popup-inquiry .cont {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#wrap #common-popup-inquiry .cont::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
#wrap #common-popup-inquiry .cont .desc {
  margin: 0 0 10px 0;
}
#wrap #common-popup-inquiry .cont .desc .inp {
  display: flex;
  padding: 0 15px;
  height: 46px;
  align-items: center;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px 5px 0 5px;
}
#wrap #common-popup-inquiry .cont .desc select.inp {
  border-color: var(--theme-text-teritary);
  background: #fff url('../img/icon-dropdown.png') no-repeat right 15px center /
    14px auto;
}
#wrap #common-popup-inquiry .cont .desc input[type='text'].inp {
  background: #f7f7f7;
  border-color: #f7f7f7;
}
#wrap #common-popup-inquiry .cont .desc textarea.inp {
  padding: 15px;
  height: 200px;
  background: #f7f7f7;
}
#wrap #common-popup-inquiry .cont .desc .select {
  position: relative;
  border-radius: 5px 5px 0 5px;
  border: 1px solid #f1f1f1;
}
#wrap #common-popup-inquiry .cont .desc .select .btnSelect {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  text-align: left;
  padding: 12px 15px;
  background: url('../img/icon-dropdown.png') no-repeat right 10px center / 12px
    6px;
}
#wrap #common-popup-inquiry .cont .desc .select .btnSelect i {
  width: 100px;
  height: 100%;
  background-size: auto 20px;
}
#wrap #common-popup-inquiry .cont .desc .select .btnSelect p {
  font-size: 14px;
  color: #171717;
}
#wrap #common-popup-inquiry .cont .desc .select ul {
  position: relative;
  top: 100%;
  background: #fff;
  display: none;
  border-top: 1px solid #f1f1f1;
}
#wrap #common-popup-inquiry .cont .desc .select ul.active {
  display: block;
}
#wrap #common-popup-inquiry .cont .desc .select li {
}
#wrap #common-popup-inquiry .cont .desc .select li + li {
  border-top: 1px solid #f1f1f1;
}
#wrap #common-popup-inquiry .cont .desc .select li label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  text-align: left;
  padding: 0 15px;
  background: #fff;
}
#wrap #common-popup-inquiry .cont .desc .select li i {
  width: 100px;
  height: 100%;
}
#wrap #common-popup-inquiry .cont .desc .select li p {
  font-size: 14px;
  color: #1f1f1f;
}
#wrap #common-popup-inquiry .cont .desc .attach {
}
#wrap #common-popup-inquiry .cont .desc .attach ul {
  display: flex;
}
#wrap #common-popup-inquiry .cont .desc .attach li {
  width: 20%;
}
#wrap #common-popup-inquiry .cont .desc .attach li:last-child .box {
  border-radius: 0 5px 0 0;
}
#wrap #common-popup-inquiry .cont .desc .attach li:nth-child(1) .box {
  border-radius: 5px 0 0 5px;
}
#wrap #common-popup-inquiry .cont .desc .attach li .box {
  position: relative;
  padding: 100% 0 0 0;
  border: 1px solid var(--theme-text-teritary);
  overflow: hidden;
}
#wrap #common-popup-inquiry .cont .desc .attach li .file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#wrap #common-popup-inquiry .cont .desc .attach li .file input {
  display: none;
}
#wrap #common-popup-inquiry .cont .desc .attach li .file i {
  width: 18px;
  height: 18px;
  background: url('../img/icon-file.png') no-repeat 50% 50% / 100% auto;
}
#wrap #common-popup-inquiry .cont .desc .attach li .file p {
  margin: 9px 0 0 0;
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-teritary);
}
#wrap #common-popup-inquiry .cont .desc .attach li .file-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
  background: url('../img/icon-file-delete.png') no-repeat 50% 50% / 100% auto;
}
#wrap #common-popup-inquiry .cont .desc .attach li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**/
#wrap #common-popup-filter .popup-cont {
  text-align: center;
}
#wrap #common-popup-filter .popup-cont .title {
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #171717;
}
#wrap #common-popup-filter .popup-cont .filter {
  margin: 20px 0 0 0;
}
#wrap #common-popup-filter .popup-cont .filter dl {
  display: flex;
  align-items: center;
}
#wrap #common-popup-filter .popup-cont .filter dt {
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
}
#wrap #common-popup-filter .popup-cont .filter dd {
  margin-left: auto;
}
#wrap #common-popup-filter .popup-cont .list {
  margin-top: 30px;
  padding-bottom: 30px;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}
#wrap #common-popup-filter .popup-cont .list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#wrap #common-popup-filter .popup-cont .list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
#wrap #common-popup-filter .popup-cont .list dl {
  text-align: left;
}
#wrap #common-popup-filter .popup-cont .list dl + dl {
  margin-top: 30px;
}
#wrap #common-popup-filter .popup-cont .list dt {
  margin: 0 0 10px 0;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
}
#wrap #common-popup-filter .popup-cont .list dt em {
  margin-left: 5px;
  line-height: 13px;
  font-size: 11px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-filter .popup-cont .list dd {
}
#wrap #common-popup-filter .popup-cont .list dd ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -10px 0;
}
#wrap #common-popup-filter .popup-cont .list dd li {
  margin: 0 10px 10px 0;
}
#wrap #common-popup-filter .popup-cont .list dd li .checkbox {
  height: auto;
}
#wrap #common-popup-filter .popup-cont .list .gauge {
}
#wrap #common-popup-filter .popup-cont .list .gauge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 0;
}
#wrap #common-popup-filter .popup-cont .list .gauge input[type='range'] {
  top: 50%;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  border: none;
  pointer-events: none;
  z-index: 10;
  appearance: none;
  transform: translateY(-50%);
  opacity: 0;
}
#wrap
  #common-popup-filter
  .popup-cont
  .list
  .gauge
  input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  appearance: none;
  background-color: #303030;
  width: 30px;
  height: 30px;
}
#wrap #common-popup-filter .popup-cont .list .gauge .track {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #d6d6d6;
}
#wrap #common-popup-filter .popup-cont .list .gauge .track .range {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  transform: translateY(-50%);
  background-color: var(--theme-blue-primary);
}
#wrap #common-popup-filter .popup-cont .list .gauge .track .thumb {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px #d3d3d3;
}
#wrap #common-popup-filter .popup-cont .list .gauge .track .thumb.min {
  left: 0;
}
#wrap #common-popup-filter .popup-cont .list .gauge .track .thumb.max {
  right: 0;
}
#wrap #common-popup-filter .popup-cont .list .result {
  display: flex;
  align-items: center;
}
#wrap #common-popup-filter .popup-cont .list .result span {
  line-height: 14px;
  font-size: 11px;
  color: var(--theme-blue-primary);
}
#wrap #common-popup-filter .popup-cont .list .result span + span {
  margin-left: auto;
}
#wrap #common-popup-filter .popup-cont .button {
  display: flex;
  margin: 0 -20px;
  padding: 17px 15px;
  border-top: 0.5px solid var(--theme-text-teritary);
  flex-direction: row;
}
#wrap #common-popup-filter .popup-cont .button .btn-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex: unset;
  width: auto;
}
#wrap #common-popup-filter .popup-cont .button .btn-reset img {
  width: 25px;
  height: 25px;
}
#wrap #common-popup-filter .popup-cont .button .btn-reset p {
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  white-space: nowrap;
  padding: 0 10px 0 0;
}
#wrap #common-popup-filter .popup-cont .button .btn {
}
#wrap #common-popup-filter .popup-cont .button .btn + .btn {
  margin-left: 20px;
}

/**/
#wrap #common-popup-sorting {
}
#wrap #common-popup-sorting .popup-cont {
  text-align: center;
}
#wrap #common-popup-sorting .popup-cont .title {
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #171717;
  margin: 0 0 16px 0;
}
#wrap #common-popup-sorting .popup-cont .list {
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#wrap #common-popup-sorting .popup-cont .list::-webkit-scrollbar {
  display: none;
}

#wrap #common-popup-sorting .popup-cont .list li {
  border-bottom: 0.5px solid #919191;
}
#wrap #common-popup-sorting .popup-cont .list li:last-child {
  border-bottom: none;
}
#wrap #common-popup-sorting .popup-cont .list li .checklist {
  cursor: pointer;
}
#wrap #common-popup-sorting .popup-cont .list li .checklist input {
  display: none;
}
#wrap #common-popup-sorting .popup-cont .list li .checklist input ~ div {
  display: flex;
  align-items: center;
  height: 55px;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
}
#wrap
  #common-popup-sorting
  .popup-cont
  .list
  li
  .checklist
  input:checked
  ~ div {
  font-weight: 500;
  color: var(--theme-blue-primary);
  background: url('../img/icon-list-checked.png') no-repeat right 5px center /
    12px auto;
}
#wrap #common-popup-sorting .popup-cont .button {
  display: flex;
  margin: 0 -20px;
  padding: 17px 15px 0;
  border-top: 0.5px solid var(--theme-text-teritary);
}

/**/
#wrap #common-popup-cancellation {
}
#wrap #common-popup-cancellation .popup-cont .title {
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #171717;
  margin: 0 0 16px 0;
}
#wrap #common-popup-cancellation .popup-cont .list {
}
#wrap #common-popup-cancellation .popup-cont .list ul {
}
#wrap #common-popup-cancellation .popup-cont .list li {
  border-bottom: 0.5px solid #919191;
}
#wrap #common-popup-cancellation .popup-cont .list li button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  background: transparent;
}
#wrap #common-popup-cancellation .popup-cont .button {
  display: flex;
  margin: 0 -20px;
  padding: 17px 15px 0;
  border-top: 0.5px solid var(--theme-text-teritary);
}

/**/
#wrap #common-popup-more {
}
#wrap #common-popup-more .popup-cont .title {
  line-height: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #171717;
  margin: 0 0 16px 0;
}
#wrap #common-popup-more .popup-cont .list {
}
#wrap #common-popup-more .popup-cont .list ul {
}
#wrap #common-popup-more .popup-cont .list li {
  border-bottom: 0.5px solid #919191;
}
#wrap #common-popup-more .popup-cont .list li .btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  background: transparent;
}
#wrap #common-popup-more .popup-cont .button {
  display: flex;
  margin: 0 -20px;
  padding: 17px 15px 0;
  border-top: 0.5px solid var(--theme-text-teritary);
}

/**/
#wrap #common-popup-delete .popup-cont .image {
  width: 50px;
  margin: 0 auto 13px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
}
#wrap #common-popup-delete .popup-cont .image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-delete .popup-cont .title {
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  margin: 0 0 7px 0;
}
#wrap #common-popup-delete .popup-cont .title strong {
  font-weight: 700;
}
#wrap #common-popup-delete .popup-cont .text {
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-delete .popup-cont .button {
  margin: 22px 0 0 0;
}

/**/
#wrap #common-popup-block .popup-cont .image {
  width: 50px;
  margin: 0 auto 13px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
}
#wrap #common-popup-block .popup-cont .image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrap #common-popup-block .popup-cont .title {
  line-height: 17px;
  font-size: 14px;
  color: #171717;
  margin: 0 0 7px 0;
}
#wrap #common-popup-block .popup-cont .title strong {
  font-weight: 700;
}
#wrap #common-popup-block .popup-cont .text {
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-block .popup-cont .button {
  margin: 22px 0 0 0;
}

/**/
#wrap #common-popup-complete .popup-box {
  border-radius: 10px 12px 0 10px;
  padding: 20px 16px 0;
}
#wrap #common-popup-complete .popup-cont {
}
#wrap #common-popup-complete .popup-cont .title {
  margin: 0 0 16px 0;
  line-height: 22px;
  font-size: 14px;
  font-weight: 700;
  color: #171717;
}
#wrap #common-popup-complete .popup-cont .text {
  line-height: 20px;
  font-size: 13px;
  color: #171717;
}

/**/
#wrap #common-popup-reservation {
}
#wrap #common-popup-reservation .popup-cont {
}
#wrap #common-popup-reservation .title {
  margin: 20px 0;
}
#wrap #common-popup-reservation .name {
  margin: 0 0 18px 0;
  padding: 0 20px;
  line-height: 16px;
  font-size: 13px;
  color: #171717;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#wrap #common-popup-reservation .name strong {
  color: #171717;
}
#wrap #common-popup-reservation .name span {
  color: #919191;
}
#wrap #common-popup-reservation .option {
  margin: 0 0 18px 0;
  padding: 0 20px;
}
#wrap #common-popup-reservation .option dl {
  display: flex;
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#wrap #common-popup-reservation .option dt {
  width: 55px;
  text-align: left;
}
#wrap #common-popup-reservation .option dd {
  color: #171717;
}
#wrap #common-popup-reservation .caution {
  line-height: 14px;
  font-size: 12px;
  color: #919191;
  text-align: left;
  padding: 0 20px;
}

/**/
#wrap #common-popup-profile {
}
#wrap #common-popup-profile .list {
}
#wrap #common-popup-profile .list ul {
}
#wrap #common-popup-profile .list li {
}
#wrap #common-popup-profile .list li + li {
  border-top: 0.5px solid var(--theme-text-secondary);
}
#wrap #common-popup-profile .list li a {
  display: flex;
  height: 50px;
  align-items: center;
  line-height: 17px;
  font-size: 14px;
}

.input-range {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;
  margin: 0 1rem;
  width: 90% !important;
}
.input-range__label {
  display: none;
}
.input-range .input-range__track {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--theme-blue-primary);
}
.input-range .input-range__track--background {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  transform: translateY(-50%);
  background-color: #d6d6d6;
}
.input-range .input-range__track--active {
  background-color: var(--theme-blue-primary);
}
.input-range .input-range__slider {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px #d3d3d3;
  border: 0 !important;
}
.input-range .input-range__slider:eq(0) {
  left: 0;
}
.input-range .input-range__slider:eq(1) {
  right: 0;
}
