.naver-map {
  height: calc(var(--vh, 1vh) * 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.naver-map .tit {
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
  margin: 0 0 15px 0;
}
.naver-map .txt {
  line-height: 14px;
  font-size: 12px;
  color: #c1c1c1;
}
.naver-map .act {
  margin: 26px 0 0 0;
}
.naver-map .act button {
  display: block;
  line-height: 18px;
  font-size: 15px;
  font-weight: 700;
  color: var(--theme-blue-primary);
  background-color: transparent;
}
