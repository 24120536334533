#mypage .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 18px;
}

#mypage .header-nickname {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme-text-primary);
}

#mypage .mypage-util {
  display: flex;
  align-items: center;
  gap: 20px;
}

#mypage .mypage-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#mypage .profile {
  position: relative;
  width: 80px;
  height: 80px;
}

.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile .edit {
  position: absolute;
  right: 0;
  bottom: 0;
}

.mypage-info .info {
  flex: 3;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mypage-info .info .info-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #919191;
  margin-right: 3px;
  position: relative;
}

.info .boundary {
  width: 1px;
  height: 9px;
  background-color: #dadce0;
}

.mypage-info .info .center {
  display: flex;
  align-items: center;
}

.mypage-info .info .info-link + span {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #555555;
}

#mypage .mypage .mypage-tab {
  padding: 0 20px;
  margin-bottom: 20px;
}

.mypage-tab-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 20px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
}
.mypage-tab-text {
  font-size: 12px;
  line-height: 14px;
  color: #919191;
}

#mypage .mypage .mypage-tab ul {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#mypage .mypage .mypage-tab li a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#mypage .mypage .mypage-tab li a p {
  margin: 10px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #919191;
}
#mypage .mypage .mypage-tab li a p em {
  margin-left: 5px;
  color: var(--theme-blue-primary);
}

#mypage .mypage .mypage-moment ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5px;
}
#mypage .mypage .mypage-moment li a {
  position: relative;
  display: block;
  padding: 150% 0 0 0;
}
#mypage .mypage .mypage-moment li .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#mypage .mypage .mypage-moment li .img img {
  width: 100%;
  height: 100%;
  display: block;
}
#mypage .mypage .mypage-moment li .con {
  position: absolute;
  bottom: 0;
  left: 0;
}
#mypage .mypage .mypage-moment li .con .view {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px;
}
#mypage .mypage .mypage-moment li .con .view i {
  width: 9px;
  height: 13px;
  margin: 0 5px 0 0;
}
#mypage .mypage .mypage-moment li .con .view i.icon-playview {
  background: url(../resources/img/icon-playview.png) no-repeat 50% 50% / 9px
    auto;
}
#mypage .mypage .mypage-moment li .con .view p {
  line-height: 13px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
}
#mypage .mypage .mypage-moment li.empty {
  width: 100%;
  height: calc(100vh - 51px - 123px - 78px - 55px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#mypage .mypage .mypage-moment li.empty .tit {
  margin: 0 0 16px 0;
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
}
#mypage .mypage .mypage-moment li.empty .txt {
  line-height: 20px;
  font-size: 12px;
  color: #c1c1c1;
  margin: 0 0 16px 0;
}
#mypage .mypage .mypage-moment li.empty .act a {
  display: flex;
  height: 41px;
  min-width: 223px;
  padding: 0 15px;
  border-radius: 10px 10px 0 10px;
  background: var(--theme-blue-primary);
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}
#mypage .mypage .mypage-moment-alert {
  position: fixed;
  bottom: 107px;
  left: 50%;
  z-index: 108;
  width: 500px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  padding: 0 20px;
  transform: translateX(-50%);
}
#mypage .mypage .mypage-moment-alert.animate {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
#mypage .mypage .mypage-moment-alert p {
  padding: 0 10px;
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 141 / 90%);
  border-radius: 5px 5px 0 5px;
  line-height: 17px;
  font-size: 14px;
  color: var(--theme-text-primary);
}
#mypage .mypage-edit {
  padding: 0 0 60px 0;
}
#mypage .mypage-edit .mypage-info {
  display: flex;
  flex-direction: column;
}
#mypage .mypage-edit .mypage-info .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#mypage .mypage-edit .mypage-info .image .profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

#mypage .mypage-edit .mypage-info .image .edit {
  position: absolute;
  right: 0;
  bottom: 0;
}

#mypage .mypage-edit .mypage-info .image .edit .pointer {
  cursor: pointer;
}

#mypage .profile-edit-complete {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme-blue-primary);
  background: transparent;
}

#mypage .mypage-edit .mypage-info .background {
  order: 1;
  height: 100px;
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#mypage .mypage-edit .mypage-info .background a {
  font-size: 14px;
  color: #ffffff;
}

#mypage .mypage-edit .mypage-info .background .isBright {
  font-size: 14px;
  color: #000000;
}
#mypage .mypage-edit .mypage-form {
  padding: 0 20px;
}
#mypage .mypage-edit .mypage-form dl + dl {
  margin-top: 30px;
}
#mypage .mypage-edit .mypage-form dt {
  margin: 0 0 10px 0;
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#mypage .mypage-edit .mypage-form dd {
  display: flex;
  align-items: center;
}
#mypage .mypage-edit .mypage-form dd input[type='text'] {
  display: flex;
  padding: 0 15px;
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 10px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
}
#mypage .mypage-edit .mypage-form dd input[type='text']:read-only {
  background: #f5f5f5;
}
#mypage .mypage-edit .mypage-form dd select {
  display: flex;
  padding: 0 15px;
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 10px;
  background: #ffffff url(../resources/img/icon-dropdown.png) no-repeat right
    10px center / 12px auto;
  border: 1px solid #f5f5f5;
  font-size: 14px;
}
#mypage .mypage-edit .mypage-form dd select + select {
  margin-left: 10px;
}
#mypage .mypage-edit .mypage-form dd .checkbox {
  width: auto;
  height: auto;
}
#mypage .mypage-edit .mypage-form dd .checkbox + .checkbox {
  margin-left: 10px;
}
#mypage .mypage-edit .mypage-form dd .checkbox input ~ div {
  padding: 0 15px;
  border-radius: 10px 10px 0 10px;
  height: 40px;
  border: 1px solid #f1f1f1;
}
#mypage .mypage-background {
  overflow: hidden;
}
#mypage .mypage-background ul {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5px;
}
#mypage .mypage-background li {
  width: 33.33%;
  padding: 0.5px;
}
#mypage .mypage-background li .background {
  position: relative;
  padding: 100% 0 0 0;
  display: block;
}
#mypage .mypage-background li .background input {
  display: none;
}
#mypage .mypage-background li .background input ~ div {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#mypage .mypage-background li .background input ~ i.icon-file-checked {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 26px;
  height: 26px;
  background: url(../resources/img/icon-file-checked.png) no-repeat 50% 50% /
    100% auto;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 0;
}
#mypage .mypage-background li .background input:checked ~ i.icon-file-checked {
  background: url(../resources/img/icon-file-checked.png) no-repeat 50% 50% /
    100% auto;
  opacity: 1;
}
#mypage .myinfo .myinfo-info {
  display: flex;
  height: 75px;
  padding: 0 20px;
  justify-content: center;
  flex-direction: column;
  border-bottom: 0.5px solid var(--theme-text-teritary);
}
#mypage .myinfo .myinfo-info .title {
  display: flex;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500;
  color: #171717;
}
#mypage .myinfo .myinfo-info .title a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
}
#mypage .myinfo .myinfo-info .title a i {
  display: block;
  width: 8px;
  height: 14px;
  margin-left: 10px;
}
#mypage .myinfo .myinfo-info .text {
  margin: 3px 0 0 0;
  line-height: 14px;
  font-size: 12px;
  color: #919191;
}
#mypage .myinfo .myinfo-list {
  padding: 30px 20px;
}
#mypage .myinfo .myinfo-list dl + dl {
  margin: 30px 0 0 0;
}
#mypage .myinfo .myinfo-list dt {
  margin: 0 0 10px 0;
  line-height: 14px;
  font-size: 12px;
  color: #919191;
}

#mypage .myinfo .myinfo-list dd ul {
  border: 1px solid #f1f1f1;
  border-radius: 10px 10px 0 10px;
  padding: 5px 10px;
}

#mypage .myinfo .myinfo-list dd li .inner {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}
#mypage .myinfo .myinfo-list dd li + li {
  border-top: 0.5px solid var(--theme-text-teritary);
}
#mypage .myinfo .myinfo-list dd li .con {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#mypage .myinfo .myinfo-list dd li .con .tit {
  line-height: 25px;
  font-size: 14px;
  color: #171717;
}
#mypage .myinfo .myinfo-list dd li .con .txt {
  line-height: 16px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#mypage .myinfo .myinfo-list dd li .act {
  flex-shrink: 0;
}
#mypage .myinfo .myinfo-list dd li .act .link {
  padding: 0 10px;
  min-width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--theme-blue-primary);
}
#mypage .myinfo .myinfo-list dd li .act .link i {
  display: block;
  width: 6px;
  height: 12px;
}
#mypage .myinfo .myinfo-list .withdraw {
  margin-top: 10px;
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#mypage .myinfo .myinfo-list .withdraw a {
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-text-secondary);
  text-decoration: underline;
}
#mypage .mypage-alert .alert-list {
  padding: 20px;
  border-top: 0.5px solid var(--theme-text-teritary);
}
#mypage .mypage-alert .alert-list ul {
  border: 1px solid #f1f1f1;
  border-radius: 10px 10px 0 10px;
  padding: 5px 10px;
}
#mypage .mypage-alert .alert-list li {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
#mypage .mypage-alert .alert-list li + li {
  border-top: 0.5px solid var(--theme-text-teritary);
}
#mypage .mypage-alert .alert-list li .con {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#mypage .mypage-alert .alert-list li .con .tit {
  line-height: 25px;
  font-size: 14px;
  color: #171717;
}
#mypage .mypage-alert .alert-list li .con .txt {
  line-height: 16px;
  font-size: 12px;
  color: var(--theme-text-secondary);
}
#mypage .mypage-alert .alert-list li .act {
  flex-shrink: 0;
}
#mypage .mypage-alert .alert-list li .act .link {
  padding: 0 10px;
  min-width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--theme-blue-primary);
}
#mypage .mypage-alert .alert-list li .act .link i {
  display: block;
  width: 6px;
  height: 12px;
}

#mypage .profile .profile-moment ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}
#mypage .profile .profile-moment li a {
  position: relative;
  display: block;
  padding: 150% 0 0 0;
}
#mypage .profile .profile-moment li .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#mypage .profile .profile-moment li .img img {
  width: 100%;
  height: 100%;
  display: block;
}
#mypage .profile .profile-moment li .con {
  position: absolute;
  bottom: 0;
  left: 0;
}
#mypage .profile .profile-moment li .con .view {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px;
}
#mypage .profile .profile-moment li .con .view i {
  width: 9px;
  height: 13px;
  margin: 0 5px 0 0;
}
#mypage .profile .profile-moment li .con .view i.icon-playview {
  background: url(../resources/img/icon-playview.png) no-repeat 50% 50% / 9px
    auto;
}
#mypage .profile .profile-moment li .con .view p {
  line-height: 13px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
}
#mypage .profile .profile-moment li.empty {
  width: 100%;
  height: calc(100vh - 51px - 123px - 78px - 55px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#mypage .profile .profile-moment li.empty .tit {
  margin: 0 0 16px 0;
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
  text-align: center;
}
#mypage .profile .profile-moment li.empty .txt {
  line-height: 20px;
  font-size: 12px;
  color: #c1c1c1;
  margin: 0 0 16px 0;
}
#mypage .profile .profile-moment li.empty .act a {
  display: flex;
  height: 41px;
  min-width: 223px;
  padding: 0 15px;
  border-radius: 10px 10px 0 10px;
  background: var(--theme-blue-primary);
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}
#mypage .profile .profile-alert {
  position: fixed;
  bottom: 94px;
  left: 50%;
  z-index: 3;
  width: 500px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: translateX(-50%);
  padding: 0 20px;
}
#mypage .profile .profile-alert.animate {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
#mypage .profile .profile-alert p {
  padding: 0 10px;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: rgba(23, 23, 23, 0.8);
  border-radius: 5px 5px 0 5px;
  line-height: 17px;
  font-size: 14px;
  color: #fff;
}
