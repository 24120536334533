#around {
  padding-top: 20px;
}
.around-list {
  padding: 0 20px 55px;
}
.around-list ul {
  flex: 1;
  flex-direction: column;
}
.around-list li {
  margin: 0 0 30px 0;
  border-radius: 15px 15px 0px 15px;
}
.around-list li .box {
  border-radius: 5px 5px 0 5px;
  overflow: hidden;
}
.around-list li .box .image {
  position: relative;
  height: 162px;
  border-radius: 5px 5px 0 5px;
  overflow: hidden;
}
.around-list li .box .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.around-list li .box .bookmark {
  position: absolute;
  bottom: 13px;
  right: 10px;
  display: block;
  background: transparent;
}
.around-list li .box .bookmark i {
  width: 15px;
  height: 20px;
  display: block;
}
.around-list li .box .cont {
  background: #fff;
  padding: 10px;
  border-radius: 0px 0px 0px 20px;
}
.around-list li .box .cont .tag {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.around-list li .box .cont .tag p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding: 0 6px;
  line-height: 10px;
  font-size: 9px;
  color: var(--theme-blue-primary);
  border-radius: 2px;
  background: rgba(0, 128, 255, 0.05);
  border: 0.5px solid var(--theme-blue-primary);
}
.around-list li .box .cont .tag p + p {
  margin-left: 5px;
}
.around-list li .box .cont .title {
  line-height: 19px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-text-primary);
  margin-bottom: 10px;
}
.around-list li .box .cont .text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.around-list li .box .cont .text div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  gap: 16px;
}
.around-list li .box .cont .text span {
  color: var(--theme-text-primary);
  display: flex;
  gap: 6px;
}

.around-list li .box .cont .text span p {
  color: #919191;
}

.around-list li .box .cont .price {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.around-list li .box .cont .price .standard {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 11px;
  color: #919191;
}
.around-list li .box .cont .price .discount {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-blue-primary);
}
.around-list li .box .cont .price .sale {
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.around-list li .box .cont .price .sale strong {
  font-size: 18px;
  color: #171717;
}
.around-list li .box .cont .price .sale em {
  margin: 5px 0 0 2px;
  font-size: 11px;
  line-height: 13px;
  color: #171717;
}

.property-trans-map {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  width: 120px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  cursor: pointer;
}

.property-trans-list {
  bottom: 180px;
}

.property-trans-map p {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}
