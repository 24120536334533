.rdrDateDisplayWrapper {
  display: none;
}

.rdrPprevButton,
.rdrNextButton {
  display: none;
}

.rdrDateRangePickerWrapper {
  height: 100%;
  width: 100%;
}

.rdrMonth {
  width: 100%;
  height: 100% !important;
}

.rdrCalendarWrapper {
  width: 100%;
  font-size: var(--theme-text-m);
}
.rdrDay {
  height: 4em;
  line-height: normal;
}

.rdrDayToday .rdrDayNumber span:after {
  display: none;
}

.rdrInfiniteMonths {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* overflow: hidden; */
  height: 100% !important;
  padding: 0 1rem;
}
.rdrInfiniteMonths > div {
  height: 100% !important;
}
.rdrInfiniteMonths > div > div {
  transform: none !important;
}
.rdrInfiniteMonths::-webkit-scrollbar {
  display: none;
}

.rdrMonthAndYearWrapper {
  display: none;
}

.rdrWeekDays {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 20;
}

.rdrDay {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.rdrMonthName {
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  color: var(--theme-text-primary);
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #ffffff;
}

.period .rdrStartEdge,
.period .rdrEndEdge {
  border-radius: 0;
  left: 0;
}

.period .rdrStartEdge {
  background: linear-gradient(to right, #ffffff00 50%, #0080ff4d 50%);
}
.period .rdrEndEdge {
  background: linear-gradient(to left, #ffffff00 50%, #0080ff4d 50%);
}

.period .rdrStartEdge + .rdrDayNumber span,
.period .rdrEndEdge + .rdrDayNumber span {
  background: #0080ff;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.period .rdrStartEdge + .rdrDayNumber::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  bottom: calc(50% - 12px);
  left: calc(50% - 1.5px);
}

.period .rdrStartEdge.rdrEndEdge {
  background: transparent;
}

.period .rdrInRange {
  background: #0080ff4d;
}

.period .rdrDayEndOfWeek > .rdrStartEdge,
.period .rdrDayStartOfWeek > .rdrEndEdge,
.period .rdrDayEndOfMonth > .rdrStartEdge,
.period .rdrDayStartOfMonth > .rdrEndEdge {
  background: transparent;
}

.period .rdrDayPassive,
.period .rdrDayPassive > .rdrDayNumber span {
  background: transparent;
}

.period_price .rdrStartEdge,
.period_price .rdrEndEdge {
  border-radius: 0;
  left: 0;
}

.period_price .rdrDayPassive {
  background: transparent;
}

.period_price .rdrDayPassive span {
  display: none;
}

.period_price .rdrStartEdge {
  background: linear-gradient(to right, #ffffff 50%, #c6e4ff 50%);
}
.period_price .rdrEndEdge {
  background: linear-gradient(to left, #ffffff 50%, #c6e4ff 50%);
}

.period_price .rdrStartEdge + .rdrDayNumber .date_container,
.period_price .rdrEndEdge + .rdrDayNumber .date_container {
  background: #0080ff;
  width: 3.2rem;
  height: 2.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.period_price .rdrStartEdge.rdrEndEdge,
.period_price .rdrDayEndOfWeek .rdrStartEdge,
.period_price .rdrDayStartOfWeek .rdrEndEdge,
.period_price .rdrDayEndOfMonth .rdrStartEdge,
.period_price .rdrDayStartOfMonth .rdrEndEdge {
  background: transparent;
}
.period_price .rdrInRange {
  background: #c6e4ff;
}

.period_price .rdrDayStartOfWeek .rdrInRange,
.period_price .rdrDayEndOfWeek .rdrInRange {
  border-radius: 0;
}

.period_price .rdrInRange + .rdrDayNumber .date_container span {
  color: #463e3f !important;
}

.period_price .rdrDayDisabled .daily_price {
  display: none;
}
