@charset "utf-8";
.icon {
  width: 18px;
  height: 18px;
}

.icon-bookmark {
  background: url(../img/icon-bookmark.png) no-repeat 50% 50% / 15px 20px;
}
.icon-bookmark-grey {
  background: url(../img/icon-bookmark-grey.png) no-repeat 50% 50% / 16px 20px;
}
.icon-history-back {
  background: url(../img/icon-history-back.png) no-repeat 50% 50% / 9px auto;
}

.icon-share {
  background: url(../img/icon-share.png) no-repeat 50% 50% / 16px auto;
}
.icon-link {
  background: url(../img/icon-link.png) no-repeat 50% 50% / 100% auto;
}
.icon-dropdown {
  background: url(../img/icon-dropdown.png) no-repeat 50% 50% / 100% auto;
}
.icon-control-checked {
  background: url(../img/icon-control-checked.png) no-repeat 50% 50% / 100% auto;
}
.icon-control-basket {
  background: url(../img/icon-control-basket.png) no-repeat 50% 50% / 100% auto;
}
.icon-dott-grey {
  background: url(../img/icon-dott-grey.png) no-repeat 50% 50% / 15px auto;
}
.icon-copy {
  background: url(../img/icon-copy.png) no-repeat 50% 50% / 100% auto;
}
.icon-moment-like {
  background: url(../img/icon-moment-like.png) no-repeat 50% 50% / 100% auto;
}
.icon-moment-like.on {
  background: url(../img/icon-moment-like-on.png) no-repeat 50% 50% / 100% auto;
}
.icon-moment-comment {
  background: url(../img/icon-moment-comment.png) no-repeat 50% 50% / 100% auto;
}
.icon-moment-share {
  background: url(../img/icon-moment-share.png) no-repeat 50% 50% / 100% auto;
}
.icon-moment-more {
  background: url(../img/icon-moment-more.png) no-repeat 50% 50% / 100% auto;
}

/**/
#wrap {
  position: relative;
  margin: 0 auto;
  width: 500px;
  max-width: 100%;
  min-height: 100vh;
  background: #ffffff;
}
#wrap .checkbox {
  cursor: pointer;
  display: flex;
  height: 20px;
  align-items: center;
}
#wrap .checkbox input {
  display: none;
}
#wrap .checkbox input ~ i {
  width: 20px;
  height: 20px;
  background: url(../img/icon-checkbox.png) no-repeat 50% 50% / 100% auto;
  flex-shrink: 0;
}
#wrap .checkbox input ~ i + span {
  margin-left: 10px;
}
#wrap .checkbox input ~ span {
  line-height: 20px;
  font-size: 14px;

  text-align: left;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#wrap .checkbox input ~ div {
  white-space: nowrap;
  border: 1px solid var(--theme-text-secondary);
  line-height: 17px;
  font-size: 14px;
  display: flex;
  min-width: 47px;
  height: 30px;
  padding: 7px 10px;
  border-radius: 10px;
  align-items: center;
}
#wrap .checkbox input ~ div img {
  height: 16px;
  margin: 0 4px 0 0;
}
#wrap .checkbox input:checked ~ i {
  background: url(../img/icon-checkbox-on.png) no-repeat 50% 50% / 100% auto;
}
#wrap .checkbox input:checked ~ div {
  font-weight: 700 !important;
  color: var(--theme-blue-primary) !important;
  border-color: var(--theme-blue-primary) !important;
}
#wrap .checkbox-star {
  height: auto;
}
#wrap .checkbox-star input ~ i {
  width: 20px;
  height: 20px;
  background: url(../img/icon-starmark.png) no-repeat 50% 50% / 100% auto;
}
#wrap .checkbox-star input:checked ~ i {
  background: url(../img/icon-starmark-on.png) no-repeat 50% 50% / 100% auto;
}
#wrap .toggle {
  position: relative;
}
#wrap .toggle input[type='checkbox'] {
  display: none;
}
#wrap .toggle input[type='checkbox'] ~ div {
  width: 55px;
  height: 30px;
  border-radius: 55px;
  background: #aaa;
  position: relative;
}
#wrap .toggle input[type='checkbox'] ~ div i {
  position: absolute;
  left: 0;
  margin: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 22px;
  transition: all 0.3s;
}
#wrap .toggle input[type='checkbox']:checked ~ div {
  background: var(--theme-blue-primary);
}
#wrap .toggle input[type='checkbox']:checked ~ div i {
  left: 50%;
  margin-left: -0.5px;
}

#wrap .button .btn {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px 10px 0 10px;
  cursor: pointer;
}
#wrap .button .btn + .btn {
  margin-left: 10px;
}
#wrap .button .btn_disabled,
#wrap .button button:disabled,
#wrap .button button[disabled],
#wrap .button input:disabled,
#wrap .button input[disabled] {
  color: #ffffff !important;
  background: #919191 !important;
  pointer-events: none !important;
}
#wrap .button .btn_none {
  border-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #919191;
}
#wrap .button .btn_01 {
  color: #ffffff;
  background: var(--theme-blue-primary);
}

#top {
  width: 100%;
  position: fixed;
  bottom: 1.876rem;
  right: 1.876rem;
  width: 3rem;
  height: 3rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 98;
}
@media screen and (min-width: 31.25rem) {
  #top {
    left: calc(50% + 10rem);
  }
}
#top img {
  width: auto;
  max-width: 10px;
  height: auto;
}
#top p {
  margin: 5px 0 0 0;
  line-height: 13px;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: 1px;
}

/**/
#header {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 101;
  height: 48px;
}
#header .menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}
#header .menu div {
  display: block;
  position: relative;
  cursor: pointer;
}
#header .menu div i {
  width: 25px;
  height: 25px;
  display: block;
}
#header .menu div + div {
  margin-left: 25px;
}
#header .menu div.on:after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #ff0000;
}

/**/
#footer {
  padding: 30px 20px 120px;
  background: #f8f8f8;
}
#footer .footer {
  position: relative;
}
#footer .logo {
  margin: 0 0 12px 0;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
  color: #818181;
}
#footer .info {
  display: flex;
  flex-direction: column;
}
#footer .info > .info-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  display: flex;
  align-items: center;
  background: transparent;
}
#footer .info > .info-button p {
  line-height: 12px;
  font-size: 10px;
  color: var(--theme-text-secondary);
}
#footer .info > .info-button i {
  width: 7px;
  height: 4px;
  margin: 0 0 0 5px;
  background: url(../img/icon-footer-info-on.png) no-repeat 50% 50% / 100% auto;
}
#footer .info > .info-layer {
  display: none;
  margin: 0 0 24px 0;
}
#footer .info > .info-layer span {
  display: block;
  line-height: 12px;
  font-size: 10px;
  color: var(--theme-text-secondary);
}
#footer .info > .info-button.on i {
  background: url(../img/icon-footer-info.png) no-repeat 50% 50% / 100% auto;
}
#footer .info > .info-button.on ~ .info-layer {
  display: block;
}
#footer .link {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
}
#footer .link a {
  position: relative;
  margin: 0 5px 5px 0;
  display: block;
  line-height: 13px;
  font-size: 11px;
  color: var(--theme-text-secondary);
}
#footer .link a + a {
  padding-left: 5px;
}
#footer .link a + a:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background: var(--theme-text-secondary);
}
#footer .caution {
  line-height: 12px;
  font-size: 10px;
  color: var(--theme-text-secondary);
}

/**/
.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

#app {
  background: #fff;
}
#app .header {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0;
  background-color: #ffffff;
}
#app .header .title {
  order: 2;
  flex: 1;
  padding: 0 50px;
  line-height: 50px;
  font-size: 17px;
  font-weight: 600;
  color: #1f1f1f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#app .header .util {
  display: flex;

  min-height: 50px;
}
#app .header .util button {
  background: transparent;
}
#app .header .tool {
  order: 3;
  display: flex;
  height: 100%;
  flex-shrink: 0;
  align-items: center;
  min-width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
}

#app .header .tool button {
  background: transparent;
  color: var(--theme-blue-primary);
  font-weight: 700;
}

#app .header .tool aside {
  background: transparent;
  display: flex;
  min-width: 40px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
#app .header .tool a i {
  display: block;
  width: 40px;
  height: 40px;
}
#app .header .tool a p {
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-blue-primary);
}
#app .header .tool .category-option {
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  height: 50px;
}
#app .header .tool .category-option div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#app .header .tool .category-option div + div {
  margin-left: 15px;
}
#app .header .tool .category-option div img {
  margin: 0 5px 0 0;
  flex-shrink: 0;
  width: 25px;
}
#app .header .tool .category-option div p {
  display: flex;
  flex-direction: column;
  line-height: 12px;
  font-size: 10px;
  font-weight: bold;
  color: var(--theme-blue-primary);
}

#app .header .tool .category-option div p span:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}
#app .bottom {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  padding: 16px 20px 35px;
  display: flex;
  gap: 10px;
  border-top: 0.5px solid var(--theme-text-teritary);
  background: #fff;
  transform: translateX(-50%);
}
#app .bottom .btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 10px 10px 0 10px;
  line-height: 19px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
#app .bottom .btn + .btn {
  margin-left: 10px;
}
#app .bottom .btn_disabled {
  background: #d2d2d2 !important;
  color: #ffffff !important;
  pointer-events: none !important;
}
#app .bottom .btn_01 {
  background: var(--theme-text-secondary);
  color: #fff;
}
#app .bottom .btn_02 {
  background: var(--theme-blue-primary);
  color: #fff;
}
#app hr.bd10 {
  position: relative;
  border-bottom: 10px solid #f1f1f1;
  width: 100%;
  display: block;
  flex-shrink: 0;
}

/**/
#appbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  z-index: 99;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.05);
}
#appbar .appbar {
  display: flex;
  justify-content: space-between;
  height: 55px;
  padding: 5px 20px;
  position: relative;
  z-index: 2;
}
#appbar .appbar a {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 30px;
}
#appbar .appbar a i {
  margin: 0 0 5px 0;
  width: 24px;
  height: 24px;
  display: block;
  flex-shrink: 0;
}
#appbar .appbar a p {
  line-height: 10px;
  font-size: 10px;
  color: var(--theme-text-secondary);
  white-space: nowrap;
}
#appbar .appbar a.on p {
  color: var(--theme-blue-primary);
}
#appbar .appbar a .icon-app-home {
  background: url(../img/icon-app-home.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-around {
  background: url(../img/icon-app-around.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-moment {
  background: url(../img/icon-app-moment.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-region {
  background: url(../img/icon-app-region.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-mypage {
  background: url(../img/icon-app-mypage.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a.on .icon-app-home {
  background: url(../img/icon-app-home-on.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a.on .icon-app-around {
  background: url(../img/icon-app-around-on.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a.on .icon-app-moment {
  background: url(../img/icon-app-moment-on.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a.on .icon-app-region {
  background: url(../img/icon-app-region-on.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a.on .icon-app-mypage {
  background: url(../img/icon-app-mypage-on.png) no-repeat 50% 50% / 24px auto;
}
/*#appbar .appbar a .icon-app-home-new{background: url(../img/icon-app-home-new.png) no-repeat 50% 50% / 24px auto}*/
#appbar .appbar a .icon-app-around-new {
  background: url(../img/icon-app-around-new.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-moment-new {
  background: url(../img/icon-app-moment-new.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-region-new {
  background: url(../img/icon-app-region-new.png) no-repeat 50% 50% / 24px auto;
}
#appbar .appbar a .icon-app-mypage-new {
  background: url(../img/icon-app-mypage-new.png) no-repeat 50% 50% / 24px auto;
}
#appbar .coupon-pack {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  padding: 10px 20px;
}
#appbar .coupon-pack .coupon-pack-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 5px 5px 0 5px;
  border: 1px solid var(--theme-blue-primary);
  background: #ffffff;
}
#appbar .coupon-pack .coupon-pack-text {
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-blue-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#appbar .coupon-pack .coupon-pack-close {
  margin-left: auto;
  width: 13px;
  height: 13px;
  background: url(../img/icon-coupon-pack-close.png) no-repeat 50% 50% / 100%
    auto;
}

/**/

#home {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.home-info-title {
  padding: 0 20px;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: justify;
  color: var(--theme-text-primary);
  white-space: pre-line;
}

#home .home-recent {
  padding: 0 20px 20px;
  border-bottom: 10px solid #f1f1f1;
  overflow: hidden;
}
#home .home-recent .home-recent-title {
  display: flex;
  margin: 0 0 16px 0;
  align-items: center;
}
#home .home-recent .home-recent-title .title {
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
}
#home .home-recent .home-recent-title .more {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1.063rem;
  font-size: var(--theme-text-m);
  color: var(--theme-blue-primary);
  font-weight: 500;
}

#home .home-recent .home-recent-slider .swiper-slide {
  position: relative;
  width: 240px;
  padding: 0 0 13px 0;
}
#home .home-recent .home-recent-slider .image {
  position: relative;
  margin-bottom: 14px;
  height: 140px;
  border-radius: 15px 15px 0 15px;
  overflow: hidden;
}
#home .home-recent .home-recent-slider .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .home-recent .home-recent-slider .title {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-text-primary);
  margin-bottom: 6px;
}

#home .home-recent .home-recent-slider .address {
  font-size: var(--theme-text-s);
  line-height: 16px;
  color: var(--theme-text-primary);
}
/**/
#curation {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/**/
#category .header {
  border-bottom: 0.5px solid var(--theme-text-teritary);
}
#category .category {
  border-top: 10px solid #f1f1f1;
  padding-top: 20px;
}

#category .category-recommand {
  padding: 0 20px 20px;
  overflow: hidden;
}
#category .category-recommand .category-recommand-title {
  display: flex;
  margin: 0 0 16px 0;
  align-items: flex-start;
}
#category .category-recommand .category-recommand-title .title {
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#category .category-recommand .category-recommand-title .title p {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--theme-text-primary);
}
#category .category-recommand .category-recommand-title .title em {
  margin: 5px 0 0 0;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
  color: var(--theme-text-secondary);
}
#category .category-recommand .category-recommand-title .more {
  margin-left: auto;
  height: 26px;
  animation-fill-mode: backwards;
}
#category .category-recommand .category-recommand-title .more a {
  font-weight: 510;
  font-size: 14px;
  line-height: 14px;
  color: var(--theme-blue-primary);
}

#category .category-recommand .category-recommand-slider .swiper-slide {
  position: relative;
  width: 45%;
  padding: 0 0 13px 0;
  cursor: pointer;
}
#category .category-recommand .category-recommand-slider .image {
  margin: 0 0 14px 0;
  height: 140px;
  border-radius: 15px 15px 0px 15px;
  overflow: hidden;
}
#category .category-recommand .category-recommand-slider .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#category .category-recommand .category-recommand-slider .title {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-text-primary);
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#category .category-recommand .category-recommand-slider {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 7px 0 0 0;
}
#category .category-recommand .category-recommand-slider .standard {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 11px;
  color: #919191;
  white-space: nowrap;
}

#category .category-recommand .category-recommand-slider .sale {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme-text-primary);
}
#category .category-recommand .category-recommand-slider .noroom {
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-text-secondary);
}

#category .tag {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
#category .tag p {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  border-radius: 5px;
  padding: 4px;
}
#category .tag p.type01 {
  border: 1px solid var(--theme-red-teritary);
  color: var(--theme-red-secondary);
}
#category p.type02 {
  border: 1px solid var(--theme-blue-teritary);
  color: var(--theme-blue-secondary);
}

#category
  .category-recommand
  .category-recommand-slider
  + .category-recommand-title {
  margin-top: 6px;
}
#category .category-tabs {
  overflow: hidden;
  flex-shrink: 0;
  border-bottom: 0.5px solid var(--theme-text-teritary);
  position: sticky;
  top: 51px;
  z-index: 5;
  background: #ffffff;
  padding: 0 20px;
}

#category .category-tabs li {
  width: auto;
  padding: 10px 0.5rem;
  cursor: pointer;
}
#category .category-tabs li span {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--theme-text-teritary);
}
#category .category-tabs li.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--theme-text-primary);
}
#category .category-tabs li.active span {
  font-weight: bold;
  color: var(--theme-text-primary);
}

#category .category-tabbox {
  padding: 15px 20px;
}
#category .category-tabbox .tab-box {
  display: none;
}
#category .category-tabbox .tab-box.active {
  display: block;
}
#category .category-tabbox ul {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}
#category .category-tabbox .category-region {
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  cursor: pointer;
}
#category .category-tabbox .category-region span {
  display: flex;
  padding: 20px;
  height: 50px;
  align-items: center;
}
#category .category-tabbox .category-region .text {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--theme-text-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#category .category-tabbox .category-region .link {
  width: 6px;
  height: 25px;
  background: url(../img/icon-list-link.png) no-repeat 50% 50% / 100% auto;
  flex-shrink: 0;
  margin-left: 15px;
}

#category .category-tablist ul {
  flex: 1;
  flex-direction: column;
  padding: 15px 0;
}
#category .category-tablist li {
  margin: 0 0 30px 0;
  border-radius: 15px 15px 0px 15px;
}
/**/
#category-list .around-list {
  padding-top: 20px;
}
#category-list .category-list-tabs {
  padding: 0 20px;
  overflow: hidden;
  flex-shrink: 0;
  border-bottom: 1px solid #f1f1f1;
}

#category-list .category-list-tabs li {
  width: auto;
  padding: 10px 0.5rem;
}
#category-list .category-list-tabs li span {
  font-size: 14px;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}
#category-list .category-list-tabs li.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #171717;
}
#category-list .category-list-tabs li.active span {
  font-weight: bold;
}

#category-detail .category-detail-list {
  padding: 0 20px 55px;
}
#category-detail .category-detail-list ul {
  flex: 1;
  flex-direction: column;
}
#category-detail .category-detail-list li {
  margin: 0 0 30px 0;
  border-radius: 15px 15px 0px 15px;
}
#category-detail .category-detail-list li.empty {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0;
}
#category-detail .category-detail-list li.empty .tit {
  line-height: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #1f1f1f;
}
#category-detail .category-detail-list li.empty .txt {
  line-height: 25px;
  font-size: 14px;
  color: #c1c1c1;
}
#category-detail .category-detail-list li .box {
  border-radius: 5px 5px 0 5px;
  overflow: hidden;
}
#category-detail .category-detail-list li .box .image {
  position: relative;
  height: 162px;
  border-radius: 5px 5px 0 5px;
  overflow: hidden;
}
#category-detail .category-detail-list li .box .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#category-detail .category-detail-list li .box .bookmark {
  position: absolute;
  bottom: 13px;
  right: 10px;
  display: block;
  background: transparent;
}
#category-detail .category-detail-list li .box .bookmark i {
  width: 15px;
  height: 20px;
  display: block;
}
#category-detail .category-detail-list li .box .cont {
  background: #fff;
  padding: 10px;
  border-radius: 0px 0px 0px 20px;
}
#category-detail .category-detail-list li .box .cont .tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
#category-detail .category-detail-list li .box .cont .tag p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding: 0 6px;
  line-height: 10px;
  font-size: 9px;
  color: var(--theme-blue-primary);
  border-radius: 2px;
  background: rgba(0, 128, 255, 0.05);
  border: 0.5px solid var(--theme-blue-primary);
}
#category-detail .category-detail-list li .box .cont .tag p + p {
  margin-left: 5px;
}
#category-detail .category-detail-list li .box .cont .title {
  line-height: 19px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-text-primary);
  margin-bottom: 10px;
}
#category-detail .category-detail-list li .box .cont .text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#category-detail .category-detail-list li .box .cont .text div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  gap: 16px;
}

#category-detail .category-detail-list li .box .cont .text span {
  color: var(--theme-text-primary);
  display: flex;
  gap: 6px;
}

#category-detail .category-detail-list li .box .cont .text span p {
  color: #919191;
}

#category-detail .category-detail-list li .box .cont .price {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#category-detail .category-detail-list li .box .cont .price .standard {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 11px;
  color: #919191;
}
#category-detail .category-detail-list li .box .cont .price .discount {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-blue-primary);
}
#category-detail .category-detail-list li .box .cont .price .sale {
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#category-detail .category-detail-list li .box .cont .price .sale strong {
  font-size: 18px;
}
#category-detail .category-detail-list li .box .cont .price .sale em {
  margin: 5px 0 0 2px;
  font-size: 11px;
  line-height: 13px;
}

/**/

#category-map .category-map {
  position: relative;
  overflow: hidden;
}
#category-map .category-map-area {
  height: calc(100vh - 51px);
  position: relative;
}
#category-map .category-map-area #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#category-map .category-map-area .location-reset {
  position: absolute;
  z-index: 11;
  top: 60px;
  right: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px 40px 0 40px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
#category-map .category-map-area .location-reset i {
  width: 22px;
  height: 22px;
  display: block;
}

#category-map .category-map-area .location-case {
  position: absolute;
  z-index: 10;
}
#category-map .category-map-area .location-case p {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 11px;
  font-weight: 700;
  height: 32px;
}
#category-map .category-map-area .location-case:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -4px;
  width: 8px;
  height: 5px;
  box-sizing: border-box;
  z-index: 1;
  border-top: 5px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0px solid transparent;
}
#category-map .category-map-area .location-case:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: -2px;
  margin-left: -4px;
  width: 8px;
  height: 6px;
  box-sizing: border-box;
  z-index: 2;
  border-top: 6px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0px solid transparent;
}
#category-map .category-map-area .location-case.case01 p {
  background: var(--theme-blue-primary);
  border-color: var(--theme-blue-primary);
  padding: 0 20px;
  font-size: 13px;
  height: 40px;
  color: #fff;
}
#category-map .category-map-area .location-case.case01:before {
  border-top-color: var(--theme-blue-primary);
}
#category-map .category-map-area .location-case.case01:after {
  border-top-color: var(--theme-blue-primary);
}
#category-map .category-map-area .location-case.case02 p {
  background: #fff;
  border-color: var(--theme-blue-primary);
  color: var(--theme-blue-primary);
}
#category-map .category-map-area .location-case.case02:before {
  border-top-color: var(--theme-blue-primary);
}
#category-map .category-map-area .location-case.case02:after {
  border-top-color: #fff;
}
#category-map .category-map-area .location-case.case03 p {
  background: var(--theme-text-secondary);
  border-color: var(--theme-text-secondary);
  color: #ffffff;
}
#category-map .category-map-area .location-case.case03:before {
  border-top-color: var(--theme-text-secondary);
}
#category-map .category-map-area .location-case.case03:after {
  border-top-color: var(--theme-text-secondary);
}
#category-map .category-map-area .location-case.case04 p {
  background: #fff;
  border-color: var(--theme-text-secondary);
  color: var(--theme-text-secondary);
}
#category-map .category-map-area .location-case.case04:before {
  border-top-color: var(--theme-text-secondary);
}
#category-map .category-map-area .location-case.case04:after {
  border-top-color: #fff;
}
/**/

/**/
#around .around-filter {
  display: none;
  padding: 10px 0 10px 20px;
  border-bottom: 0.5px solid var(--theme-text-teritary);
  background: #ffffff;
  align-items: center;
}
#around .around-filter.on {
  display: flex;
}
#around .around-filter .btnFilter {
  flex-shrink: 0;
  height: 30px;
  display: flex;
  margin: 0 18px 0 0;
  align-items: center;
  background: transparent;
}
#around .around-filter .btnFilter .icon {
  position: relative;
  flex-shrink: 0;
  margin: 0 5px 0 0;
  width: 17px;
  height: 17px;
  background: url(../img/icon-filter-blue.png) no-repeat 50% 50% / 100% auto;
}
#around .around-filter .btnFilter .icon.on:after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #ff0000;
}
#around .around-filter .btnFilter .text {
  line-height: 13px;
  font-size: 11px;
  font-weight: bold;
  color: var(--theme-blue-primary);
}
#around .around-filter .record {
  display: block;
  flex: 1;
  overflow: hidden;
}
#around .around-filter .record ul {
  overflow-x: auto;
  display: flex;
  white-space: nowrap;
}
#around .around-filter .record ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#around .around-filter .record ul::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
#around .around-filter .record li:last-child {
  margin-right: 20px;
}
#around .around-filter .record li + li {
  margin-left: 10px;
}
#around .around-filter .record li button {
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 30px;
  border-radius: 5px 5px 0 5px;
  border: 1px solid var(--theme-blue-primary);
  background: var(--theme-blue-primary);
}
#around .around-filter .record li button .text {
  line-height: 13px;
  font-size: 11px;
  font-weight: bold;
  color: var(--theme-blue-primary);
}
#around .around-filter .record li button .icon {
  margin-left: 5px;
  background: url(../img/icon-record-delete.png) no-repeat 50% 50% / 100% auto;
  width: 9px;
  height: 9px;
}

#around #top {
  bottom: 5.876rem;
}

#address .address {
  position: relative;
  overflow: hidden;
}
#address .address .address-map {
  height: calc(var(--vh, 1vh) * 100 - 50px);
  position: relative;
  z-index: 1;
}
#address .address .address-map #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#address .address .address-copy {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
#address .address .address-copy .btn-copy {
  padding: 8px 14px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 5px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
#address .address .address-copy .btn-copy i {
  width: 24px;
  height: 24px;
  margin: 0 5px 0 0;
}
#address .address .address-copy .btn-copy p {
  line-height: 24px;
  font-size: 14px;
  color: #555;
}

.refund-policy {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.refund-policy.red {
  color: var(--theme-red-primary);
}

.refund-policy.blue {
  color: var(--theme-blue-primary);
}

/**/
#recent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#recent .header {
  border-bottom: 0.5px solid var(--theme-text-teritary);
}
#recent .header .title {
  text-align: left;
}
#recent .header .tool .btn-edit-mode {
  display: block;
}
#recent .header .tool .btn-edit-complete {
  display: none;
}
#recent .recent-list {
  padding: 15px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#recent .recent-list ul {
  display: flex;
  flex-direction: column;
  flex: 1;
}
#recent .recent-list li {
  position: relative;
}
#recent .recent-list li + li {
  margin-top: 30px;
}
#recent .recent-list li .inner {
  position: relative;
  z-index: 1;
}
#recent .recent-list li .inner .img {
  margin: 0 0 10px 0;
  height: 162px;
}
#recent .recent-list li .inner .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px 10px 0 10px;
}
#recent .recent-list li .inner .tit {
  margin: 0 0 6px 0;
  line-height: 19px;
  font-size: 16px;
}
#recent .recent-list li .inner .txt {
  line-height: 14px;
  font-size: 12px;
  color: #515151;
}
#recent .recent-list li .checked {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
#recent .recent-list li .checked input {
  display: none;
}
#recent .recent-list li .checked input ~ i {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: url(../img/icon-recent-checkbox.png) no-repeat 50% 50% / 100% auto;
}
#recent .recent-list li .checked input:checked ~ i {
  background: url(../img/icon-recent-checkbox-on.png) no-repeat 50% 50% / 100%
    auto;
}
#recent .recent-list li.empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#recent .recent-list li.empty .title {
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
  margin: 0 0 16px 0;
}

#recent .recent-list li.empty .link a {
  display: flex;
  height: 40px;
  min-width: 220px;
  border-radius: 5px 5px 0 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: var(--theme-blue-primary);
}
#recent .recent-control {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 80px;
  background: var(--theme-blue-primary);
  padding: 10px 20px;
  display: none;
  align-items: flex-start;
}
#recent .recent-control .btn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#recent .recent-control .btn input {
  display: none;
}
#recent .recent-control .btn i {
  width: 21px;
  height: 21px;
}
#recent .recent-control .btn p {
  margin: 10px 0 0 0;
  line-height: 12px;
  font-size: 10px;
  color: #fff;
}
#recent .recent-checked-alert {
  position: fixed;
  bottom: 94px;
  left: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
#recent .recent-checked-alert.animate {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
#recent .recent-checked-alert p {
  padding: 0 10px;
  display: flex;
  width: auto;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: rgba(23, 23, 23, 0.8);
  border-radius: 5px 5px 0 5px;
  line-height: 17px;
  font-size: 14px;
  color: #fff;
}
#recent #top {
  bottom: 20px;
}
#recent.edit-mode .header .title {
  text-align: center;
}
#recent.edit-mode .header .tool .btn-edit-mode {
  display: none;
}
#recent.edit-mode .header .tool .btn-edit-complete {
  display: block;
}
#recent.edit-mode .recent-list {
  padding-bottom: 95px;
}
#recent.edit-mode .recent-list li .checked {
  display: block;
}
#recent.edit-mode .recent-control {
  display: flex;
}
#recent.edit-mode #top {
  bottom: 112px;
}
/**/
#withdraw {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#withdraw .withdraw {
  flex: 1;
  text-align: center;

  display: flex;
  flex-direction: column;
}
#withdraw .withdraw .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--theme-text-primary);
  padding: 20px 0;
  border-bottom: 0.5px solid var(--theme-text-teritary);
}
#withdraw .withdraw .list ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

#withdraw .withdraw .list li {
  text-align: left;
}

#withdraw .withdraw .list li label.radio {
  display: flex;
  align-items: center;
  gap: 6px;
}

#withdraw .withdraw .list li label.radio ~ textarea {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px 5px 0 5px;
  padding: 13px 15px;
  background: #f8f8f8;
  height: 135px;
  display: none;
}
#withdraw .withdraw .list li label.radio.active ~ textarea {
  display: block;
}

#withdraw .withdraw .radio input {
  display: none;
}

#withdraw .withdraw .radio input ~ i {
  width: 20px;
  height: 20px;
  background: url(../img/icon-checkbox.png) no-repeat 50% 50% / 100% auto;
}

#withdraw .withdraw .radio input:checked ~ i {
  background: url(../img/icon-checkbox-on.png) no-repeat 50% 50% / 100% auto;
}

#withdraw .withdraw-caution {
  flex: 1;
  text-align: center;
  padding: 30px 20px 155px;
  display: flex;
  flex-direction: column;
}
#withdraw .withdraw-caution .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--theme-text-primary);
  margin-bottom: 20px;
}
#withdraw .withdraw-caution .title span {
  color: var(--theme-blue-primary);
}
#withdraw .withdraw-caution .text {
  line-height: 16px;
  font-size: 13px;
  color: #919191;
  margin: 0 0 36px 0;
}

#withdraw .withdraw-caution .text span.blue {
  color: var(--theme-blue-primary);
}

#withdraw .withdraw-caution .text span.red {
  color: var(--theme-red-primary);
}

#withdraw .withdraw-caution .info li {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  height: 58px;
  background: #fff;
  padding: 0 20px;
  align-items: center;
}
#withdraw .withdraw-caution .info li + li {
  margin-top: 10px;
}
#withdraw .withdraw-caution .info li .tit {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--theme-text-primary);
  display: flex;
  align-items: center;
}
#withdraw .withdraw-caution .info li .tit span {
  color: var(--theme-blue-primary);
}
#withdraw .withdraw-caution .info li .txt {
  margin-left: auto;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-blue-primary);
  text-align: right;
  white-space: nowrap;
}
#withdraw .withdraw-caution .read {
  margin-top: 36px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
#withdraw .withdraw-caution .read p {
  line-height: 16px;
  font-size: 13px;
  color: #919191;
  display: flex;
  align-items: flex-start;
}
#withdraw .withdraw-caution .read p:before {
  content: '';
  margin: 7px;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background: #919191;
  display: inline-flex;
  flex-shrink: 0;
}
#withdraw .withdraw-caution .read p + p {
  margin-top: 18px;
}
#withdraw .withdraw-caution .agree {
  position: fixed;
  bottom: 96px;
  left: 50%;
  width: 500px;
  max-width: 100%;
  padding: 10px 20px 30px;
  background: #fff;
  transform: translateX(-50%);
}

#withdraw .withdraw-btn {
  background: #d2d2d2;
  border-radius: 10px 10px 0px 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  flex: 1;
}

#withdraw .withdraw-btn.next-btn {
  background: var(--theme-blue-primary);
}

#withdraw .withdraw-btn.next-btn:disabled {
  background: #d2d2d2;
}

/**/
/* moment page basic */
html.black-bg {
  overflow: hidden;
}
html.black-bg #wrap {
  background: transparent;
}
html.black-bg #app {
  display: flex;
  flex-direction: column;
  padding: 0 0 55px 0;
  height: 100vh;
  background: transparent;
}

@media (hover: none) and (pointer: coarse) {
  html.black-bg #app {
    height: calc(var(--vh, 1vh) * 100);
    /* margin-top: calc(0px + env(safe-area-inset-top));
    margin-top: calc(0px + constant(safe-area-inset-top)); */
  }
}

html.black-bg #appbar {
  background: transparent;
  box-shadow: none;
}
html.black-bg #appbar:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background: #191919;
  transform: translateX(-50%);
}
html.black-bg #appbar .appbar a p {
  color: var(--theme-text-secondary);
}
html.black-bg #appbar .appbar a .icon-app-home {
  background: url(../img/icon-app-home-black.png) no-repeat 50% 50% / 24px auto;
}
html.black-bg #appbar .appbar a .icon-app-around {
  background: url(../img/icon-app-around-black.png) no-repeat 50% 50% / 24px
    auto;
}
html.black-bg #appbar .appbar a .icon-app-region {
  background: url(../img/icon-app-region-black.png) no-repeat 50% 50% / 24px
    auto;
}
html.black-bg #appbar .appbar a .icon-app-mypage {
  background: url(../img/icon-app-mypage-black.png) no-repeat 50% 50% / 24px
    auto;
}
html.black-bg #appbar .appbar a.on p {
  color: var(--theme-blue-primary);
}
html.black-bg #appbar .appbar a.on .icon-app-moment {
  background: url(../img/icon-app-moment-black-on.png) no-repeat 50% 50% / 24px
    auto;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.d-none {
  display: none;
}
