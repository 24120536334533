.category-map {
  position: relative;
  overflow: hidden;
}
.category-map-area {
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
}
.category-map-area #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.category-map-area .location-reset {
  position: absolute;
  z-index: 11;
  top: 60px;
  right: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px 40px 0 40px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-map-area .location-reset i {
  width: 22px;
  height: 22px;
  display: block;
}

.location-case {
  position: absolute;
  z-index: 10;
}
.location-case p {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 11px;
  font-weight: 700;
  height: 32px;
}
.location-case:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -4px;
  width: 8px;
  height: 5px;
  box-sizing: border-box;
  z-index: 1;
  border-top: 5px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0px solid transparent;
}
.location-case:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: -2px;
  margin-left: -4px;
  width: 8px;
  height: 6px;
  box-sizing: border-box;
  z-index: 2;
  border-top: 6px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0px solid transparent;
}
.location-case.case01 p {
  background: var(--theme-blue-primary);
  border-color: var(--theme-blue-primary);
  padding: 0 20px;
  font-weight: bold;
  color: #fff;
}
.location-case.case01:before {
  border-top-color: var(--theme-blue-primary);
}
.location-case.case01:after {
  border-top-color: var(--theme-blue-primary);
}
.location-case.case02 p {
  background: #fff;
  border-color: var(--theme-blue-primary);
  color: var(--theme-blue-primary);
}
.location-case.case02:before {
  border-top-color: var(--theme-blue-primary);
}
.location-case.case02:after {
  border-top-color: #fff;
}
.location-case.case03 p {
  background: var(--theme-text-secondary);
  border-color: var(--theme-text-secondary);
  color: #ffffff;
}
.location-case.case03:before {
  border-top-color: var(--theme-text-secondary);
}
.location-case.case03:after {
  border-top-color: var(--theme-text-secondary);
}
.location-case.case04 p {
  background: #fff;
  border-color: var(--theme-text-secondary);
  color: var(--theme-text-secondary);
}
.location-case.case04:before {
  border-top-color: var(--theme-text-secondary);
}
.location-case.case04:after {
  border-top-color: #fff;
}

.category-map-list {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0 17px 34px 17px;
}
.category-map-list .swiper-slide {
  margin-right: 10px;
}
.category-map-list .box {
  display: flex;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0px 20px;
  cursor: pointer;
}
.category-map-list .box .image {
  width: 106px;
  height: 106px;
  border-radius: 15px 15px 0px 15px;
  overflow: hidden;
}
.category-map-list .box .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-map-list .box .cont {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
}
.category-map-list .box .cont .tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 4px;
}
.category-map-list .box .cont .tag p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  border-radius: 5px;
  color: var(--theme-blue-secondary);
  border: 1px solid var(--theme-blue-teritary);
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
}

.category-map-list .box .cont .tag p.price-tag {
  color: var(--theme-red-secondary);
  border: 1px solid var(--theme-red-teritary);
}

.category-map-list .box .cont .title {
  line-height: 19px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-text-primary);
  margin-bottom: 10px;
}
.category-map-list .box .cont .text {
  margin: 4px 0 0 0;
}

.category-map-list .box .cont .price {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  position: absolute;
  bottom: 8px;
  right: 12px;
}
.category-map-list .box .cont .price .standard {
  margin: 0 5px 0 0;
  line-height: 21px;
  font-size: 11px;
  color: #919191;
}
.category-map-list .box .cont .price .discount {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: line-through;
  color: var(--theme-text-teritary);
}
.category-map-list .box .cont .price .sale {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme-text-primary);
}
.category-map-list .box .cont .price .sale strong {
  font-size: 18px;
  color: #171717;
}
.category-map-list .box .cont .price .sale em {
  margin: 5px 0 0 2px;
  font-size: 11px;
  line-height: 13px;
  color: #171717;
}

.unavailble-property {
  font-weight: bold;
  color: var(--theme-text-secondary);
}

.research-box {
  width: 190px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  position: absolute;
  z-index: 100;
  top: 40px;
  right: 0;
  left: 0;
  margin: 0 auto;
  cursor: pointer;
}

.research-box_text {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
