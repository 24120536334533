#region {
  padding: 20px 0 55px 0;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

#region .sticky {
  position: sticky;
  top: 0;
}

#region .tab-box,
#region .tab-box2 #region .tab-box3 {
  display: none;
  flex: 1;
  flex-direction: column;
}
#region .tab-box.active,
#region .tab-box2.active #region .tab-box3.active {
  display: flex;
}
#region .region-tabs {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  border-bottom: 1px solid #f1f1f1;
}
#region .region-tabs li {
  flex: 1;

  text-align: center;
  padding-bottom: 10px;
  position: relative;
}
#region .region-tabs li span {
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}
#region .region-tabs li.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5px;
  background: #171717;
}
#region .region-tabs li.active span {
  font-weight: bold;
  color: #171717;
}
#region .region-tablist {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#region .region-tablist .region-tablist-tabs {
  padding: 12px 20px;
  overflow: hidden;
  flex-shrink: 0;
  width: 100%;
  background: #f8f8f8;
  border-bottom: 1px solid #f1f1f1;
}
#region .region-tablist .region-tablist-tabs ul {
  display: flex;
  justify-content: space-between;
}
#region .region-tablist .region-tablist-tabs li {
  flex: 1;
  text-align: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 4px 0;
  cursor: pointer;
}
#region .region-tablist .region-tablist-tabs li span {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}
#region .region-tablist .region-tablist-tabs li.active {
  background: var(--theme-blue-primary);
}

#region .region-tablist .region-tablist-tabs li.active span {
  color: #ffffff;
}
#region .region-tablist .region-tablist-box {
  padding: 15px 20px;
  display: flex;
  height: calc(var(--vh, 1vh) * 100 - 170px);
}
#region .region-tablist .region-tablist-left {
  width: 30%;
  flex-shrink: 0;
  margin: 0 10px 0 0;
}

.scroll-none {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-none::-webkit-scrollbar {
  display: none;
}

#region .region-tablist .region-tablist-left ul {
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
}

#region .region-tablist .region-tablist-left li span {
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  line-height: 25px;
  font-size: 14px;
  color: var(--theme-text-secondary);
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
#region .region-tablist .region-tablist-left li i {
  width: 25px;
  height: 25px;
}
#region .region-tablist .region-tablist-left li.active span {
  font-weight: bold;
  color: var(--theme-blue-primary);
}
#region .region-tablist .region-tablist-left li.active.green {
  color: #1dc800;
}
#region .region-tablist .region-tablist-left li.active.orange {
  color: #f06a00;
}
#region .region-tablist .region-tablist-left li + li {
  margin-top: 10px;
}
#region .region-tablist .region-tablist-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#region .region-tablist .region-tablist-right ul {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#region .region-tablist .region-tablist-right li {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 5%;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
}
#region .region-tablist .region-tablist-right li + li {
  margin-top: 15px;
}
#region .region-tablist .region-tablist-right li label {
  flex-shrink: 0;
  margin: 0 10px 0 0;
}
#region .region-tablist .region-tablist-right li a {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
}
#region .region-tablist .region-tablist-right li .text {
  flex: 1;
  line-height: 25px;
  font-size: 14px;
  color: #171717;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
#region .region-tablist .region-tablist-right li .link {
  width: 6px;
  height: 25px;
  background: url(../resources/img/icon-list-link.png) no-repeat 50% 50% / 100%
    auto;
  flex-shrink: 0;
  margin-left: 5px;
}
#region .region-tablist .region-tablist-right li.empty {
  height: 200px;
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 0 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#region .region-tablist .region-tablist-right li.empty .tit {
  line-height: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #c1c1c1;
}
#region .region-tablist .region-tablist-right li.empty .txt {
  margin-top: 15px;
  line-height: 20px;
  font-size: 12px;
  color: #c1c1c1;
}
#region .region-tablist .region-tablist-map {
  flex: 1;
  background: #eee;
  position: relative;
}
#region .region-tablist .region-tablist-map .empty {
  min-height: calc(100vh - 51px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: 0.5px solid var(--theme-text-teritary);
}
#region .region-tablist .region-tablist-map .empty .tit {
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
  margin: 0 0 15px 0;
}
#region .region-tablist .region-tablist-map .empty .txt {
  line-height: 14px;
  font-size: 12px;
  color: #c1c1c1;
}
#region .region-tablist .region-tablist-map .empty .act {
  margin: 26px 0 0 0;
}
#region .region-tablist .region-tablist-map .empty .act a {
  display: block;
  line-height: 18px;
  font-size: 15px;
  font-weight: 700;
  color: var(--theme-blue-primary);
}
#region .region-tablist .region-tablist-map #map {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#region .region-tablist .region-tablist-map-option {
  flex-shrink: 0;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  background: #ffffff;
}
#region .region-tablist .region-tablist-map-option .region-map-position {
  margin: 0 30px 0 0;
  display: flex;
  align-items: center;
}
#region .region-tablist .region-tablist-map-option .region-map-position i {
  margin: 0 8px 0 0;
  width: 19px;
  height: 19px;
  flex-shrink: 0;
}
#region .region-tablist .region-tablist-map-option .region-map-position p {
  line-height: 19px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
}
#region .region-tablist .region-tablist-map-option .region-map-button {
  flex: 1;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 5px;
  background: var(--theme-blue-primary);
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

#region .bookmark {
  height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
