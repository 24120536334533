#wishlist {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#wishlist .wishlist {
  padding: 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#wishlist .wishlist ul {
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 20px;
}
