@charset "utf-8";
@import url('//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');

@import url('//fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@font-face {
  font-family: 'SF Pro';
  font-weight: 400;
  src: local('SF Pro'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff2')
      format('woff2'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  src: local('SF Pro'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff2')
      format('woff2'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 700;
  src: local('SF Pro'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff2')
      format('woff2'),
    url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff')
      format('woff');
  font-display: swap;
}

/* 스타일시트 초기화 */
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font-family: 'SF Pro', 'Noto Sans KR', 'Malgun Gothic', 'dotum', '돋움',
    'gulim', '굴림', 'Tahoma';
  letter-spacing: 0px;
}

html {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  /* scroll-behavior: smooth; */
  background: #dedede;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
html.black-bg {
  background: #191919;
}
body {
  font-size: 16px;
  color: #333;
  background: #ffffff;
  letter-spacing: 2px;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
a {
  color: #222;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ins {
  color: #000;
  text-decoration: none;
}
del {
  color: #aaa;
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
button {
  cursor: pointer;
}
pre,
code,
kbd,
samp,
tt {
  font-size: 108%;
  line-height: 100%;
}
legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
select {
  font-size: 1.4rem;
  border: 1px solid #ccc;
  border-radius: 0;
}
label,
input,
button,
select,
img {
  vertical-align: middle;
}
select {
  margin: 0;
}
em,
address,
cite {
  font-style: normal;
}
input,
select,
textarea,
button {
  border-radius: 0;
  resize: none;
}
p {
  margin: 0;
  padding: 0;
  word-break: break-all;
}
i {
  font-style: normal;
}
caption,
hr {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  line-height: 0;
  font-size: 0;
  border: 0;
  overflow: hidden;
}
pre {
  overflow-x: scroll;
  font-size: 1.1em;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
*::placeholder {
  color: #ccc;
}
*::selection {
  background: var(--theme-blue-primary);
  color: #fff;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input:read-only {
  background: #d7d7d7;
}

.skip {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

.swiper-pagination {
  position: static;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #d2d2d2;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  border: 2px solid var(--theme-button-one-stroke);
  background: #d2d2d2;
  /* background: transparent; */
}

.moment-button-prev {
  position: fixed;
  top: 50px;
  right: 100px;
  width: 70px;
  height: 70px;
  background: url(../img/icon-moment-prev.png) no-repeat 50% 50% / cover;
  cursor: pointer;
  z-index: 100;
}

.moment-button-next {
  position: fixed;
  bottom: 50px;
  right: 100px;
  width: 70px;
  height: 70px;
  background: url(../img/icon-moment-next.png) no-repeat 50% 50% / cover;
  cursor: pointer;
  z-index: 100;
}
@media screen and (max-width: 1024px) {
  .moment-button-prev,
  .moment-button-next {
    display: none;
  }
}
