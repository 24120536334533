/**/
#coupon {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#coupon .coupon-form {
  padding: 10px 20px;
  flex-shrink: 0;
  position: sticky;
  top: 51px;
  background: #fff;
  z-index: 5;
}

#coupon .coupon-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
}

#coupon .coupon-util {
  position: absolute;
  left: 20px;
  top: 20px;
}

#coupon .coupon-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--theme-text-primary);
  text-align: center;
}

#coupon .coupon-form-inner {
  display: flex;
  align-items: center;
}
#coupon .coupon-form-inner input {
  height: 44px;
  padding: 0 24px;
  border-radius: 10px 10px 0 10px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  flex: 1;
  min-width: 100px;
}
#coupon .coupon-form-inner button {
  flex-shrink: 0;
  margin: 0 0 0 20px;
  width: 115px;
  height: 44px;
  border-radius: 10px 10px 0 10px;
  background: var(--theme-blue-primary);
  color: #fff;
  font-size: 14px;
}
#coupon .coupon-list {
  padding: 15px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#coupon .coupon-list ul {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#coupon .coupon-list li {
  cursor: pointer;
}

#coupon .coupon-list li .inner {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid;
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border: none;
}

#coupon .coupon-list li .inner.check {
  border: 1px solid var(--theme-blue-primary);
  box-shadow: none;
}

#coupon .coupon-list li .inner .sale {
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 14px;
  font-size: 12px;
  color: var(--theme-blue-primary);
}
#coupon .coupon-list li .inner .title {
  margin: 0 0 3px 0;
  line-height: 17px;
  font-size: 14px;
  color: #171717;
}
#coupon .coupon-list li .inner .text {
  line-height: 15px;
  font-size: 10px;
  color: var(--theme-text-secondary);
}
#coupon .coupon-list li.empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#coupon .coupon-list li.empty .title {
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #c1c1c1;
}

#coupon .coupon-btn {
  width: 100%;
  padding: 12px;
  background: var(--theme-blue-primary);
  border-radius: 10px 10px 0px 10px;
  font-weight: 510;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

#coupon .checked {
  position: absolute;
  right: 20px;
  top: calc(50% - 16px);
}

#coupon .coupon-button {
  border-top: 0.5px solid var(--theme-text-teritary);
  padding: 16px 20px 34px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  z-index: 10;
  background: #ffffff;
  transform: translateX(-50%);
}
#coupon .coupon-button .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 44px;
  line-height: 19px;
  font-size: 16px;
  font-weight: 700;
}
#coupon .coupon-button .btn + .btn {
  margin-left: 16px;
}
